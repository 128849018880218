import FormComponent from "./form-component";
import { FormParams } from "./input";

export interface Option {
  id: string;
  value: string;
}
export interface SelectParams extends FormParams {
  options: Option[];
}

export default function Select(params: SelectParams) {
  return (
    <FormComponent
      id={params.id}
      label={params.label}
      className={params.className}
    >
      <select
        id={params.id}
        name={params.id}
        onChange={params.onChange}
        required={params.required}
        value={params.value}
        className="w-full outline-0 rounded-t-md border-0 border-b-2 border-gray-400 focus:border-indigo-500 focus:ring-0 text-lg lg:text-xl"
      >
        {params.options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.value}
          </option>
        ))}
      </select>
    </FormComponent>
  );
}
