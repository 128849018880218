import { FILTERS } from "./life-event.constants";

export const IMAGE_FILTERS = [
  {
    id: 1,
    title: "None",
    imageUrl: FILTERS.NONE,
    config: {
      brightness: 100,
      contrast: 100,
      grayscale: 0,
      hue: 0,
      saturation: 100,
    },
  },
  {
    id: 2,
    title: "Black & White",
    imageUrl: FILTERS.BLACK_AND_WHITE,
    config: {
      brightness: 100,
      contrast: 100,
      grayscale: 100,
      hue: 0,
      saturation: 100,
    },
  },
  {
    id: 3,
    title: "Desaturate",
    imageUrl: FILTERS.DESATURATE,
    config: {
      brightness: 100,
      contrast: 100,
      grayscale: 0,
      hue: 0,
      saturation: 50,
    },
  },
  {
    id: 4,
    title: "Brighten",
    imageUrl: FILTERS.BRIGHTEN,
    config: {
      brightness: 150,
      contrast: 100,
      grayscale: 0,
      hue: 0,
      saturation: 100,
    },
  },
  {
    id: 5,
    title: "Darken",
    imageUrl: FILTERS.DARKEN,
    config: {
      brightness: 50,
      contrast: 100,
      grayscale: 0,
      hue: 0,
      saturation: 100,
    },
  },
  {
    id: 6,
    title: "Contrast",
    imageUrl: FILTERS.CONTRAST,
    config: {
      brightness: 100,
      contrast: 150,
      grayscale: 0,
      hue: 0,
      saturation: 100,
    },
  },
];
