import { useMatch } from "react-router-dom";
import Footer from "../../../components/footer";
import Header from "../../../components/header/header";
import LoginOverlay from "../../auth/overlays/login.overlay";
import RegisterCompleteOverlay from "../../auth/overlays/register-complete.overlay";
import RegisterOverlay from "../../auth/overlays/register.overlay";
import AsSeenOnComponent from "../components/as-seen-on.component";
import HeroComponent from "../components/hero.component";
import IntroComponent from "../components/intro.component";
import MobileSliderComponent from "../components/mobile-slider.component";
import SquareTilesComponent from "../components/square-tiles.component";
import TestimonialSliderComponent from "../components/testimonial-slider.component";

export default function HomePage() {
  const matchLogin = useMatch("/login");
  const matchRegister = useMatch("/register");
  const matchRegisterComplete = useMatch("/register-complete");

  return (
    <>
      <Header />
      <main>
        <HeroComponent />
        <IntroComponent />
        <MobileSliderComponent />
        <SquareTilesComponent />
        <TestimonialSliderComponent />
        <AsSeenOnComponent />
      </main>

      <Footer />

      {matchLogin && <LoginOverlay />}
      {matchRegister && <RegisterOverlay />}
      {matchRegisterComplete && <RegisterCompleteOverlay />}
    </>
  );
}
