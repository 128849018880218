import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

interface ImageEditSliderParams {
  id: string;
  title: string;
  initialState: number;
  scale: number;
  min: number;
  max: number;
  onChange: (val: number) => void;
  disabled: boolean;
}

export default function ImageEditSliderComponent(
  params: ImageEditSliderParams
) {
  const [sliderVal, setSliderVal] = useState(params.initialState);

  const onNegativeClick = () => {
    const newVal = sliderVal - params.scale;
    setSliderVal(newVal);
    params.onChange(newVal);
  };

  const onPositiveClick = () => {
    const newVal = sliderVal + params.scale;
    setSliderVal(newVal);
    params.onChange(newVal);
  };

  const onInputChange = (e: any) => {
    const newVal = parseFloat(e.target.value);
    setSliderVal(newVal);
    params.onChange(newVal);
  };

  return (
    <>
      <label className="text-center text-sm font-medium text-gray-700">
        {params.title}
      </label>
      <div className="flex justify-center">
        <button
          type="button"
          onClick={onNegativeClick}
          disabled={params.disabled}
          className="inline-flex items-center rounded-full border border-transparent bg-white p-1 text-white shadow-sm hover:bg-slate-300 focus:outline-none focus:ring-2 focus:ring-slate-300 focus:ring-offset-2"
        >
          <MinusCircleIcon className="mx-auto h-5 w-5 text-black " />
        </button>
        <div className="px-2 self-center">
          <input
            type="range"
            className="my-1"
            id={params.id}
            name={params.id}
            min={params.min}
            max={params.max}
            value={sliderVal}
            onChange={onInputChange}
            step={params.scale}
            disabled={params.disabled}
          />
        </div>
        <button
          type="button"
          onClick={onPositiveClick}
          disabled={params.disabled}
          className="inline-flex items-center rounded-full border border-transparent bg-white p-1 text-white shadow-sm hover:bg-slate-300 focus:outline-none focus:ring-2 focus:ring-slate-300 focus:ring-offset-2"
        >
          <PlusCircleIcon className="mx-auto h-5 w-5 text-black " />
        </button>
      </div>
    </>
  );
}
