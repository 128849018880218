import {
  ArrowsPointingOutIcon,
  DocumentArrowDownIcon,
  PencilSquareIcon,
  PlayIcon,
  ShareIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import ModalSquare from "../../../components/modal-square";
import Overlay from "../../../components/overlay";
import {} from "../../../shared/services/file-upload.service";
import EditLifeEventComponent from "../components/edit-life-event.component";
import LifeEventComponent from "../components/life-event.component";
import SelectCoverImageStyleComponent from "../components/select-cover-image-style.component";
import UploadAndEditImageComponent from "../components/upload-and-edit-image.component";
import { LifeEventEntity } from "../entities/life-event.entity";

export default function LifeEventOverlay({
  isOpen,
  onClose,
  lifeEvent,
  activeIndex,
  todayIndex,
  editLifeEvent,
  deleteLifeEvent,
}: {
  isOpen: boolean;
  onClose: any;
  lifeEvent: LifeEventEntity;
  activeIndex: number;
  todayIndex?: number;
  editLifeEvent: (lifeEvent: LifeEventEntity) => void;
  deleteLifeEvent: (id: number) => void;
}) {
  const [editMode, setEditMode] = useState(false);
  const [page, setPage] = useState(0);
  const [selectedCoverImageStyleId, setSelectedCoverImageStyleId] = useState(0);
  const [formValues, setFormValues] = useState<LifeEventEntity>(lifeEvent);

  useEffect(() => {
    if (isOpen) {
      setFormValues(lifeEvent);
      setEditMode(false);
      setPage(0);
    }
  }, [setEditMode, setPage, isOpen, lifeEvent]);

  const submitNewImage = (newImageName: string | undefined) => {
    const values = formValues!;
    console.log(values);
    const updatedEvent: LifeEventEntity = new LifeEventEntity({
      id: lifeEvent.id,
      title: values.title,
      startDate: values.startDate,
      endDate: values.endDate,
      description: values.description,
      category: values.category,
      imageName: newImageName,
      customTags: values.customTags,
      isDateRangeVerified: values.isDateRangeVerified,
      isAdditionalMedia: false,
    });
    editLifeEvent(updatedEvent);
    onClose();
  };

  const submitWithoutImage = async () => {
    const values = formValues!;

    const updatedEvent: LifeEventEntity = new LifeEventEntity({
      id: lifeEvent.id,
      title: values.title,
      startDate: values.startDate,
      endDate: values.endDate,
      description: values.description,
      category: values.category,
      imageName: values.imageName,
      customTags: values.customTags,
      isDateRangeVerified: values.isDateRangeVerified,
      isAdditionalMedia: values.isAdditionalMedia,
    });

    editLifeEvent(updatedEvent);
    onClose();
  };

  const iconsTop = editMode
    ? []
    : [
        <ArrowsPointingOutIcon className="h-6 w-6" />,
        <ShareIcon className="h-6 w-6" />,
        <PlayIcon className="h-6 w-6" />,
      ];
  const iconsBottom = editMode
    ? [
        <DocumentArrowDownIcon
          className="h-6 w-6"
          onClick={() => {
            setEditMode(false);
            setPage(0);
          }}
        />,
      ]
    : [
        <PencilSquareIcon
          className="h-6 w-6"
          onClick={() => setEditMode(true)}
        />,
      ];

  const onClickEditImage = () => {
    setPage(1);
  };

  return (
    <>
      {lifeEvent && (
        <Overlay
          open={isOpen}
          onClose={onClose}
          iconsTop={iconsTop}
          iconsBottom={iconsBottom}
        >
          <div className="flex flex-col md:flex-row">
            {page === 0 ? (
              <>
                {editMode ? (
                  <ModalSquare>
                    <div
                      className="bg-cover h-full w-full flex justify-center items-center"
                      style={{
                        backgroundImage: `url(${lifeEvent.imageUrl})`,
                      }}
                    >
                      <button
                        onClick={onClickEditImage}
                        className="p-2 bg-slate-700 text-white outline-dashed outline-slate-700 outline-offset-8"
                      >
                        Edit image
                      </button>
                    </div>
                  </ModalSquare>
                ) : (
                  <ModalSquare>
                    <img
                      src={lifeEvent.imageUrl}
                      alt="Upload preview"
                      className="object-cover w-full h-full"
                    />
                  </ModalSquare>
                )}
                {editMode ? (
                  <EditLifeEventComponent
                    formValues={formValues}
                    setFormValues={setFormValues}
                    handleSubmit={submitWithoutImage}
                    onClose={onClose}
                    lifeEvent={lifeEvent}
                    deleteLifeEvent={deleteLifeEvent}
                  />
                ) : (
                  <LifeEventComponent
                    lifeEvent={lifeEvent}
                    activeIndex={activeIndex}
                    todayIndex={todayIndex}
                  />
                )}
              </>
            ) : page === 1 ? (
              <SelectCoverImageStyleComponent
                onClickNext={(selectedCoverImageStyle) => {
                  setSelectedCoverImageStyleId(selectedCoverImageStyle);
                  setPage(2);
                }}
                onClickBack={() => setPage(0)}
              />
            ) : (
              <UploadAndEditImageComponent
                coverImageStyleId={selectedCoverImageStyleId}
                onClickDone={submitNewImage}
                onClickBack={() => setPage(1)}
              />
            )}
          </div>
        </Overlay>
      )}
    </>
  );
}
