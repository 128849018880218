import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import ReactDom from "react-dom";

export default function Overlay({
  open,
  children,
  onClose,
  iconsTop,
  iconsBottom,
  noSidebar = false,
}: {
  open: boolean;
  children: any;
  onClose: any;
  iconsTop?: any[];
  iconsBottom?: any[];
  noSidebar?: boolean;
}) {
  if (!open) return null;

  const generateTopIconList = () => {
    if (iconsTop && iconsTop.length > 0) {
      return iconsTop.map((icon, index) => (
        <React.Fragment key={index}>
          <div className="py-2"></div>
          {icon}
        </React.Fragment>
      ));
    }
  };
  const generateBottomIconList = () => {
    if (iconsBottom && iconsBottom.length > 0) {
      return iconsBottom.map((icon, index) => (
        <React.Fragment key={index}>
          <div className="py-2"></div>
          {icon}
        </React.Fragment>
      ));
    }
  };

  return ReactDom.createPortal(
    <>
      <div className="fixed inset-0 bg-black/70 z-[1000]" />
      <div
        className="fixed top-2/4 left-2/4 p-10 z-[1000] flex "
        style={{ transform: "translate(-50%, -50%)" }}
      >
        {children}
        <div
          className={`flex flex-col ${
            noSidebar ? "bg-transparent" : "bg-black/70 "
          } ml-2 text-white px-2 py-3`}
        >
          <XMarkIcon className="h-6 w-6" onClick={onClose} />
          <>{generateTopIconList()}</>
          <div className="grow"></div>
          <>{generateBottomIconList()}</>
        </div>
      </div>
    </>,
    document.getElementById("overlay") as HTMLElement
  );
}
