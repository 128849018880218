import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { WORD_LIST } from "../constants/home.constants";

export default function IntroComponent() {
  const [wordIndex, setWordIndex] = useState(0);
  const [show, setShow] = useState(true);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setShow(true);
    }, 10);
    return () => {
      clearTimeout(timeout);
    };
  }, [wordIndex]);

  return (
    <section className="relative flex justify-center overflow-hidden -mt-10 md:-mt-14 xl:-mt-32 h-[700px] min-h-full">
      <video autoPlay loop muted className="absolute max-w-none">
        <source
          src="https://vidavu-assests-stage.s3.amazonaws.com/assets/intro_video.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <div className="relative flex flex-col items-center mx-6 md:mx-0 h-[700px] min-h-full justify-center">
        <h1 className="text-white text-6xl md:text-8xl">vidavu</h1>
        <h2 className="text-white text-3xl md:text-4xl">~IS YOUR~</h2>

        <Transition
          show={show}
          appear={true}
          enter="transition-opacity ease-out duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-1000 ease-in delay-[2000ms]"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterEnter={() => {
            setShow(false);
          }}
          afterLeave={() => {
            setWordIndex((wordIndex + 1) % WORD_LIST.length);
          }}
        >
          <p className="text-white text-6xl md:text-8xl italic text-center">
            {WORD_LIST[wordIndex]}
          </p>
        </Transition>
      </div>
    </section>
  );
}
