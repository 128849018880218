import { Fragment, useState } from "react";
import useWindowDimensions, {
  WIDTH_PREFIX,
} from "../../../shared/hooks/get-window-dimensions.hook";
import { formatDate } from "../../../shared/utils/date.utils";
import {
  AdditionalMediaEntity,
  MediaType,
} from "../entities/additional-media.entity";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { LifeEventEntity } from "../entities/life-event.entity";
import AdditionalMediaEditComponent from "./additional-media-edit.component";

export default function AdditionalMediaViewComponent({
  lifeEvent,
  additionalMedia,
}: {
  lifeEvent: LifeEventEntity;
  additionalMedia: AdditionalMediaEntity;
}) {
  const [editMode, setEditMode] = useState<boolean>(false);
  const { width } = useWindowDimensions();
  let imageWidth = 400;

  if (width < WIDTH_PREFIX.xl) {
    imageWidth = 200;
  } else {
    imageWidth = 400;
  }
  const imageHeight = (imageWidth * 9) / 16;

  return (
    <>
      {editMode ? (
        <div>
          <AdditionalMediaEditComponent
            additionalMedia={additionalMedia}
            lifeEvent={lifeEvent}
            onCancel={() => setEditMode(false)}
          />
        </div>
      ) : (
        <div className="relative flex flex-col rounded-lg border border-gray-200">
          <Menu as="div" className="absolute right-0 p-2">
            <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Open options</span>
              <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-0.5 w-20 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => setEditMode(true)}
                      className={`
                    ${active ? "bg-gray-50" : ""}
                    block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left`}
                    >
                      Edit<span className="sr-only">, {"client.name"}</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`
                    ${active ? "bg-gray-50" : ""}
                    block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left`}
                    >
                      Delete<span className="sr-only">, {"client.name"}</span>
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
          <div className="aspect-h-4 aspect-w-3 sm:aspect-none group-hover:opacity-75 mt-4">
            {additionalMedia.mediaType === MediaType.VIDEO ? (
              <iframe
                title="More detail"
                width={imageWidth}
                height={imageHeight}
                src={additionalMedia.mediaUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                scrolling="no"
              ></iframe>
            ) : (
              <img
                className={`max-w-[${imageWidth}px] max-h-[${imageWidth}px] mx-auto`}
                src={additionalMedia.mediaUrl}
                alt="More detail"
              />
            )}
          </div>
          <div className="flex flex-1 flex-col space-y-2 p-4">
            <p className="text-sm text-gray-500 text-justify">
              <span className="text-[#0e847d] font-bold">
                {formatDate(additionalMedia.date)}
              </span>{" "}
              | {additionalMedia.description}
            </p>
          </div>
        </div>
      )}
    </>
  );
}
