export const StartPageImage = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  return (
    <div className="flex flex-col w-2/4 h-full self-center justify-around items-center text-end pl-4 pr-4 md:pr-6 lg:pr-8">
      <h1 className="text-xs md:text-base lg:text-xl font-bold">{title}</h1>
      <h4 className="text-xs md:text-base lg:text-xl font-bold">{subtitle}</h4>
    </div>
  );
};
