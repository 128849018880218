import { create } from "zustand";
import {
  createPageApi,
  deletePageApi,
  editPageApi,
  getPagesApi,
} from "../apis/life-event.api";
import { CATEGORY_DEFAULT } from "../constants/life-event.constants";
import { LifeEventEntity } from "../entities/life-event.entity";
import { getLifeEventDataIndex } from "../helpers/life-event.helper";
import { LifeEventModel } from "../models/life-event.model";

interface LifeEventState {
  lifeEvents: LifeEventEntity[];
  activeIndex: number;
  selectedLifeEvent: LifeEventEntity;
  todayPageIndex: number;
  totalLength: number;
  perPage: number;
  setActiveIndex: (activeIndex: number) => void;
  setLifeEvents: (activeIndex?: number) => void;
  editLifeEvent: (lifeEvent: LifeEventEntity) => void;
  createLifeEvent: (lifeEvent: LifeEventEntity) => void;
  deleteLifeEvent: (id: number) => void;
}

export const useLifeEventStore = create<LifeEventState>((set, get) => ({
  lifeEvents: [],
  activeIndex: 0,
  selectedLifeEvent: LifeEventEntity.createLoadingLifeEvent(0),
  todayPageIndex: 0,
  totalLength: 0,
  perPage: 0,
  setActiveIndex: async (activeIndex) => {
    const perPage = get().perPage;
    const todayPageIndex = get().todayPageIndex;

    // Dynamically added today page adds one extra page
    const pageNumber =
      activeIndex < todayPageIndex
        ? Math.ceil((activeIndex + 1) / perPage)
        : Math.ceil(activeIndex / perPage);

    const lifeEvents = get().lifeEvents;
    if (!(lifeEvents || lifeEvents[activeIndex])) return;
    if (lifeEvents[activeIndex].title === "Loading...") {
      const response = await getPagesApi(pageNumber);
      const data = response.data.data;
      const total = response.data.meta.total + 1; // +1 for today page
      const todayPageIndex = response.data.meta.todayPageIndex - 1;
      const lifeEventPage: LifeEventEntity[] = data.map((item: any) =>
        new LifeEventModel(item).toEntity()
      );
      let totalCoverflow: LifeEventEntity[] = [];

      // Generate coverflow for each ranges
      for (let i = 0; i < total; i++) {
        if (i < todayPageIndex && i < (pageNumber - 1) * perPage) {
          totalCoverflow.push(lifeEvents[i]);
        } else if (i < todayPageIndex && i < pageNumber * perPage) {
          totalCoverflow.push(lifeEventPage[i - (pageNumber - 1) * perPage]);
        } else if (i < todayPageIndex && i >= pageNumber * perPage) {
          totalCoverflow.push(lifeEvents[i]);
        } else if (i === todayPageIndex) {
          totalCoverflow.push({
            id: 0,
            title: "",
            startDate: new Date().toISOString(),
            description:
              "Today is the first day of the rest of your life.” –Abbie Hoffman",
            thumbnail:
              "https://vidavu-assests-stage.s3.amazonaws.com/today_pages/today-page-1.png",
            imageUrl:
              "https://vidavu-assests-stage.s3.amazonaws.com/today_pages/inspiration-left-panel.png",
            category: CATEGORY_DEFAULT,
            isDateRangeVerified: false,
            isAdditionalMedia: false,
          });
        } else if (i > todayPageIndex && i <= (pageNumber - 1) * perPage) {
          totalCoverflow.push(lifeEvents[i]);
        } else if (i > todayPageIndex && i <= pageNumber * perPage) {
          totalCoverflow.push(
            lifeEventPage[i - (pageNumber - 1) * perPage - 1]
          );
        } else if (i > todayPageIndex && i > pageNumber * perPage) {
          totalCoverflow.push(lifeEvents[i]);
        }
      }
      set((state) => ({
        lifeEvents: totalCoverflow,
      }));
    }
    set((state) => ({
      activeIndex,
      selectedLifeEvent: state.lifeEvents[activeIndex],
    }));
  },
  setLifeEvents: async (activeIndex?: number) => {
    let todayPageIndex: number = 0;
    let lifeEvents: LifeEventEntity[] = [];
    let totalLength: number = 0;
    let perPage: number = 0;

    try {
      const response = await getPagesApi(1);
      totalLength = response.data.meta.total + 1; // +1 for today page
      perPage = response.data.meta.perPage;
      const data = response.data.data;

      todayPageIndex = response.data.meta.todayPageIndex - 1;

      for (let i = 0; i < totalLength; i++) {
        const dataIndex = getLifeEventDataIndex(i, todayPageIndex);
        if (i === todayPageIndex) {
          lifeEvents.push(
            LifeEventEntity.createLoadingLifeEvent(todayPageIndex)
          );
        } else if (i < todayPageIndex && i < data.length) {
          lifeEvents.push(new LifeEventModel(data[dataIndex]).toEntity());
        } else if (i > todayPageIndex && i < data.length + 1) {
          lifeEvents.push(new LifeEventModel(data[dataIndex]).toEntity());
        } else {
          lifeEvents.push(LifeEventEntity.createLoadingLifeEvent(i));
        }
      }
    } catch (e) {
      console.error(e);
    }

    set({
      lifeEvents,
      todayPageIndex: todayPageIndex,
      totalLength,
      perPage,
      activeIndex: activeIndex ?? todayPageIndex,
      selectedLifeEvent: lifeEvents[activeIndex ?? todayPageIndex],
    });

    get().setActiveIndex(activeIndex ?? todayPageIndex);
  },
  editLifeEvent: async (lifeEvent: LifeEventEntity) => {
    try {
      const response = await editPageApi(LifeEventModel.fromEntity(lifeEvent));
      get().setLifeEvents(response.data.pageIndex - 1);
    } catch (e) {
      console.error(e);
    }
  },

  createLifeEvent: async (lifeEvent: LifeEventEntity) => {
    try {
      const response = await createPageApi(
        LifeEventModel.fromEntity(lifeEvent)
      );
      get().setLifeEvents(response.data.pageIndex - 1);
    } catch (e) {
      console.error(e);
    }
  },
  deleteLifeEvent: async (id: number) => {
    try {
      await deletePageApi(id);

      get().setLifeEvents();
    } catch (e) {
      console.error(e);
    }
  },
}));
