import { UserRegisterEntity } from "../entities/user-register.entity";

interface IUserRegisterModel {
  firstName: string;
  lastName: string;
  email: string;
  birthYear: string;
  password: string;
  passwordConfirmation: string;
}
export class UserRegisterModel {
  firstName: string;
  lastName: string;
  email: string;
  birthYear: string;
  password: string;
  passwordConfirmation: string;

  constructor(data: IUserRegisterModel) {
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.birthYear = data.birthYear;
    this.password = data.password;
    this.passwordConfirmation = data.passwordConfirmation;
  }

  static fromEntity(entity: UserRegisterEntity) {
    return new UserRegisterModel({
      firstName: entity.firstName,
      lastName: entity.lastName,
      email: entity.email,
      birthYear: entity.birthYear,
      password: entity.password,
      passwordConfirmation: entity.confirmPassword,
    });
  }
}
