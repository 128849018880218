import { ForgotPasswordEmailEntity } from "../entities/forgot-password-email.entity";

interface IForgotPasswordEmailModel {
  email: string;
}
export class ForgotPasswordEmailModel {
  email: string;

  constructor(data: IForgotPasswordEmailModel) {
    this.email = data.email;
  }

  static fromEntity(entity: ForgotPasswordEmailEntity) {
    return new ForgotPasswordEmailModel({
      email: entity.email,
    });
  }
}
