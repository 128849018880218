export interface CheckboxParams {
  id: string;
  label: string;
  required?: boolean;
  onChange?: any;
  className?: string;
  disabled?: boolean;
  checked?: boolean;
}

export default function CheckboxInput(params: CheckboxParams) {
  return (
    <div className={`flex items-center ${params.className}`}>
      <div className="flex h-5 items-center">
        <input
          id={params.id}
          name={params.id}
          type="checkbox"
          checked={params.checked}
          onChange={params.onChange}
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        />
      </div>
      <div className="ml-3 text-sm text-justify">
        <label htmlFor={params.id} className="text-gray-700">
          {params.label}
        </label>
      </div>
    </div>
  );
}
