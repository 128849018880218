import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import VVButton from "../../../components/buttons/vv-button";
import EmailInput from "../../../components/form/email-input";
import PasswordInput from "../../../components/form/password-input";
import LogoSmall from "../../../components/logo/logo-small";
import ModalSquare from "../../../components/modal-square";
import Overlay from "../../../components/overlay";
import { FormFunctions, useForm } from "../../../shared/hooks/use-form.hook";
import { loginApi } from "../apis/auth.api";
import ErrorMessage from "../components/error-message";
import { LOGIN_VIDEO } from "../constants/login.constants";
import { UserLoginEntity } from "../entities/user-login.entity";
import { useAuthStore } from "../store/auth.store";

export default function LoginOverlay() {
  const navigate = useNavigate();
  const login = useAuthStore((state) => state.login);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    loginApi(formFunc.values)
      .then((res) => {
        setErrorMessage(null);
        login(res.data.token);
        navigate("/user");
      })
      .catch((e) => {
        setErrorMessage(e.response.data.message);
      });
  };

  const formFunc: FormFunctions<UserLoginEntity> = useForm(handleSubmit, {
    email: "",
    password: "",
  });

  return (
    <Overlay
      open={true}
      onClose={() => {
        navigate("/");
      }}
    >
      <div className="flex flex-col md:flex-row">
        <ModalSquare>
          <video autoPlay loop muted className="">
            <source src={LOGIN_VIDEO} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </ModalSquare>
        <ModalSquare className="overflow-scroll">
          <div className="flex min-h-full flex-col justify-center py-4 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <LogoSmall />

              <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900">
                Welcome back
              </h2>
            </div>

            <div className="mt-8">
              {errorMessage && (
                <div className="py-2">
                  <ErrorMessage message={errorMessage} />
                </div>
              )}
              <form className="flex flex-col space-y-6" onSubmit={handleSubmit}>
                <EmailInput
                  id="email"
                  label="Email address"
                  required
                  value={formFunc.values.email}
                  onChange={formFunc.onChange}
                />
                <PasswordInput
                  id="password"
                  required
                  label="Password"
                  value={formFunc.values.password}
                  onChange={formFunc.onChange}
                />
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm">
                    <Link
                      to="/forgot-password"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Forgot password
                    </Link>
                  </div>
                </div>

                <VVButton
                  type="submit"
                  className="bg-indigo-600 self-center text-white"
                >
                  Sign In
                </VVButton>
              </form>

              <p className="mt-8 text-center text-sm text-gray-600">
                Don't have an account?{" "}
                <Link
                  to="/register"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Sign up for free
                </Link>
              </p>
            </div>
          </div>
        </ModalSquare>
      </div>
    </Overlay>
  );
}
