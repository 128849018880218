import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import VVButton from "../../../components/buttons/vv-button";
import EmailInput from "../../../components/form/email-input";
import PasswordInput from "../../../components/form/password-input";
import Select from "../../../components/form/select";
import TextInput from "../../../components/form/text-input";
import LogoSmall from "../../../components/logo/logo-small";
import ModalSquare from "../../../components/modal-square";
import Overlay from "../../../components/overlay";
import { FormFunctions, useForm } from "../../../shared/hooks/use-form.hook";
import { registerApi } from "../apis/auth.api";
import ErrorMessage from "../components/error-message";
import { REGISTER_VIDEO } from "../constants/register.constants";
import { UserRegisterEntity } from "../entities/user-register.entity";
import { UserRegisterModel } from "../models/user-register.model";

export default function RegisterOverlay() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    registerApi(UserRegisterModel.fromEntity(formFunc.values))
      .then((res) => {
        navigate("/register-complete");
      })
      .catch((e) => {
        setErrorMessage(e.response.data.message);
      });
  };

  const formFunc: FormFunctions<UserRegisterEntity> = useForm(handleSubmit, {
    firstName: "",
    lastName: "",
    email: "",
    birthYear: "",
    password: "",
    confirmPassword: "",
  });

  return (
    <Overlay
      open={true}
      onClose={() => {
        navigate("/");
      }}
    >
      <div className="flex flex-col md:flex-row">
        <ModalSquare>
          <video autoPlay loop muted className="">
            <source src={REGISTER_VIDEO} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </ModalSquare>
        <ModalSquare className="overflow-scroll">
          <div className="flex min-h-full flex-col justify-center py-4 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <LogoSmall />

              <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900">
                Create new account
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Or{" "}
                <Link
                  to="/login"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Sign In
                </Link>
              </p>
            </div>

            <div className="mt-8">
              {errorMessage && (
                <div className="py-2">
                  <ErrorMessage message={errorMessage} />
                </div>
              )}
              <form
                className="space-y-6 flex flex-col"
                action="#"
                method="POST"
                onSubmit={handleSubmit}
              >
                <div className="flex flex-col md:flex-row">
                  <TextInput
                    className="flex-1 mx-1"
                    id="firstName"
                    label="First Name"
                    required
                    value={formFunc.values.firstName}
                    onChange={formFunc.onChange}
                  />
                  <TextInput
                    className="flex-1 mx-1"
                    id="lastName"
                    label="Last Name"
                    required
                    value={formFunc.values.lastName}
                    onChange={formFunc.onChange}
                  />
                </div>

                <div className="flex flex-col md:flex-row">
                  <Select
                    className="flex-1 mx-1"
                    id="birthYear"
                    label="Year of Birth"
                    required
                    options={Array.from(Array(100).keys()).map((i) => ({
                      id: (new Date().getFullYear() - i).toString(),
                      value: (new Date().getFullYear() - i).toString(),
                    }))}
                    value={formFunc.values.birthYear}
                    onChange={formFunc.onChange}
                  />
                  <EmailInput
                    className="flex-1 mx-1"
                    id="email"
                    label="Email address"
                    required
                    value={formFunc.values.email}
                    onChange={formFunc.onChange}
                  />
                </div>
                <div className="flex flex-col md:flex-row">
                  <PasswordInput
                    className="flex-1 mx-1"
                    id="password"
                    required
                    label="Password"
                    value={formFunc.values.password}
                    onChange={formFunc.onChange}
                  />
                  <PasswordInput
                    className="flex-1 mx-1"
                    id="confirmPassword"
                    required
                    label="Confirm Password"
                    value={formFunc.values.confirmPassword}
                    onChange={formFunc.onChange}
                  />
                </div>

                <VVButton
                  type="submit"
                  className="bg-indigo-600 self-center text-white"
                >
                  Create new account
                </VVButton>
              </form>
            </div>
          </div>
        </ModalSquare>
      </div>
    </Overlay>
  );
}
