export const formatDate = (dateString: string) => {
  const date = dateString.substring(0,10).split('-');
  return [
    date[1], date[2], date[0]
  ].join(".");
};

export const showOnlyYear = (dateString: string) => {
  const date = dateString.substring(0,10).split('-');
  return date[0];
}