import { FlashboardParamsEntity } from "../entities/flashboard-params.entity";

export class FlashboardParamsModel {
  pin: string;

  constructor({ pin }: { pin: string }) {
    this.pin = pin;
  }

  static fromEntity(entity: FlashboardParamsEntity) {
    return new FlashboardParamsModel({
      pin: [entity.input1, entity.input2, entity.input3, entity.input4].join(
        ""
      ),
    });
  }
}
