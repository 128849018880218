import Resizer from "react-image-file-resizer";
import { http } from "./http.service";

export enum FileUploadCategory {
  PROFILE = "profilePicture",
  PAGE = "page",
  ADDITIONAL_MEDIA = "additionalMedia",
}

const resizeFile = (file: File, category: FileUploadCategory) => {
  if (category === FileUploadCategory.ADDITIONAL_MEDIA) {
    return new Promise<any>((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  } else {
    return new Promise<any>((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64",
        1200,
        1200
      );
    });
  }
};

export const uploadFile = async (
  uploadFile: File,
  category: FileUploadCategory
) => {
  if (uploadFile) {
    const sideLoadResponse = await http({
      url: `get-upload-url?category=${category}&format=png&mutimediaType=image`,
      method: "GET",
      auth: true,
    });

    let uploadURL = sideLoadResponse.data.url;
    const newFileName = sideLoadResponse.data.fileName;
    let blob = await resizeFile(uploadFile, category);
    let binary = atob(blob.split(",")[1]);
    let array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    let blobData = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
    await fetch(uploadURL, {
      method: "PUT",
      body: blobData,
    });

    return newFileName;
  }
};
