import { Link } from "react-router-dom";

export default function VVLinkButton({
  label,
  url,
  className,
}: {
  label: string;
  url: string;
  className?: string;
}) {
  return (
    <Link
      to={url}
      className={`rounded-full px-2 md:px-3 py-2 text-sm md:text-base hover:scale-105 ease-in duration-100 ${
        className ?? ""
      }`}
    >
      {label}
    </Link>
  );
}
