import { http } from "../../../shared/services/http.service";
import { LifeEventModel } from "../models/life-event.model";

export const createPageApi = async (data: LifeEventModel) =>
  http({ url: "page", method: "POST", auth: true, data });

export const getPagesApi = async (page: number) =>
  http({
    url: "page",
    method: "GET",
    auth: true,
    params: {
      page,
    },
  });

export const editPageApi = async (data: LifeEventModel) =>
  http({ url: "page", method: "PUT", auth: true, data });

export const deletePageApi = async (id: number) =>
  http({ url: `page/${id}`, method: "DELETE", auth: true });
