import { useEffect, useRef, useState } from "react";
import { getQuizardsApi } from "../apis/quizard.api";
import { QuizardEntity } from "../entities/quizard.entity";
import { IQuizardModel, QuizardModel } from "../models/quizard.model";
import { QuizardListItem } from "./quizard-list-item.component";
import { QuizardModalComponent } from "./quizard-modal.component";

export const QuizardList = () => {
  const [isQuizardOpen, setIsQuizardOpen] = useState(false);
  const [selectedQuizard, setSelectedQuizard] = useState<QuizardEntity>();
  const initEffect = useRef<boolean>(false);
  const [quizardsList, setQuizardsList] = useState([]);

  const onClickQuizard = (quizard: QuizardEntity) => {
    setSelectedQuizard(quizard);
    setIsQuizardOpen(true);
  };

  useEffect(() => {
    if (initEffect.current === false) {
      getQuizardsApi(1).then((res) => {
        setQuizardsList(
          res.data.data.map((quizard: IQuizardModel) =>
            new QuizardModel(quizard).toEntity()
          ) ?? []
        );
      });
      return () => {
        initEffect.current = true;
      };
    }
  });

  return (
    <section className="mt-6 container mx-auto px-4">
      <div className="flex bg-black text-white p-2 my-2 gap-x-4">
        <h4>Sort by:</h4>
        <button>Newest</button>
        <button>Featured</button>
        <button>Started</button>
        <button>In Progress</button>
      </div>
      <div className="flex flex-col gap-4">
        {quizardsList.map((quizard: QuizardEntity) => (
          <QuizardListItem
            key={quizard.id}
            quizard={quizard}
            onClickView={onClickQuizard}
          />
        ))}
      </div>

      <QuizardModalComponent
        isOpen={isQuizardOpen}
        onClose={() => setIsQuizardOpen(false)}
        quizard={selectedQuizard!}
      />
    </section>
  );
};
