import VVButton from "../../../components/buttons/vv-button";
import CheckboxInput from "../../../components/form/checkbox-input";
import DateInput from "../../../components/form/date-input";
import Select from "../../../components/form/select";
import TextArea from "../../../components/form/text-area";
import TextInput from "../../../components/form/text-input";
import ModalSquare from "../../../components/modal-square";
import { FormFunctions, useForm } from "../../../shared/hooks/use-form.hook";
import { upperCase } from "../../../shared/utils/helper.utils";
import { CATEGORY_LIST } from "../constants/life-event.constants";
import { LifeEventEntity } from "../entities/life-event.entity";

export default function CreateLifeEventComponent({
  lifeEvent,
  updateLifeEvent,
}: {
  lifeEvent: LifeEventEntity;
  updateLifeEvent: (lifeEvent: LifeEventEntity) => void;
}) {
  const handleSubmit = async () => {
    const values = formFunc.values;

    const updatedEvent: LifeEventEntity = new LifeEventEntity({
      ...lifeEvent,
      title: values.title,
      startDate: values.startDate,
      endDate: values.endDate,
      description: values.description,
      customTags: values.customTags,
      category: values.category,
      imageUrl: undefined,
      isDateRangeVerified: values.isDateRangeVerified,
      isAdditionalMedia: false,
    });

    updateLifeEvent(updatedEvent);
  };

  const toUppercase = (e: any) => {
    e.target.value = upperCase(e.target.value);
  };

  const formFunc: FormFunctions<LifeEventEntity> = useForm(
    handleSubmit,
    lifeEvent
  );

  return (
    <ModalSquare className="px-6 overflow-scroll">
      <form onSubmit={formFunc.onSubmit} className="flex flex-col my-4">
        <Select
          className="p-2"
          id="category"
          label="Select a general life category for this page"
          options={CATEGORY_LIST}
          onChange={formFunc.onChange}
          value={formFunc.values.category}
        />
        <TextInput
          className="p-2"
          id="title"
          label="Headline"
          value={formFunc.values.title}
          onChange={formFunc.onChange}
          onInput={toUppercase}
          required
          placeholder="HEADLINE GOES HERE (up to 40 characters)"
        />
        <TextArea
          className="p-2"
          id="description"
          label="Description"
          value={formFunc.values.description}
          onChange={formFunc.onChange}
          maxLength={100}
          required
          placeholder="Enter your page description here. (up to 100 characters)"
        />
        <div className="flex flex-col lg:flex-row">
          <DateInput
            className="basis-full p-2"
            id="startDate"
            label="Start Date"
            value={formFunc.values.startDate}
            onChange={formFunc.onChange}
            required
          />
          <DateInput
            className="basis-full p-2"
            id="endDate"
            label="End Date"
            value={formFunc.values.endDate}
            onChange={formFunc.onChange}
          />
        </div>

        <CheckboxInput
          className="p-2"
          id="isDateRangeVerified"
          checked={formFunc.values.isDateRangeVerified}
          onChange={formFunc.onCheck}
          label="Check this box if you are certain about the date(s). This will help better organize your life line as you create it. You can always update them later"
        />
        <TextInput
          className="p-2"
          id="customTags"
          label="Tags"
          value={formFunc.values.customTags}
          onChange={formFunc.onChange}
          placeholder="Enter custom hashtags here to help search. (commas to separate)"
        />
        <div className="p-2">
          <VVButton
            className="bg-indigo-600 self-center text-white"
            type="submit"
          >
            Next Step
          </VVButton>
        </div>
      </form>
    </ModalSquare>
  );
}
