import { ForgotPasswordResetEntity } from "../entities/forgot-password-reset.entity";

interface IForgotPasswordResetModel {
  email: string;
  OTP: string;
  newPassword: string;
  confirmNewPassword: string;
}
export class ForgotPasswordResetModel {
  email: string;
  OTP: string;
  newPassword: string;
  confirmNewPassword: string;

  constructor(data: IForgotPasswordResetModel) {
    this.email = data.email;
    this.OTP = data.OTP;
    this.newPassword = data.newPassword;
    this.confirmNewPassword = data.confirmNewPassword;
  }

  static fromEntity(entity: ForgotPasswordResetEntity) {
    return new ForgotPasswordResetModel({
      email: entity.email,
      OTP: entity.OTP,
      newPassword: entity.newPassword,
      confirmNewPassword: entity.confirmNewPassword,
    });
  }
}
