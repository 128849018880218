import exploreImg1 from "../../../assets/explore_images/explore_1.png";
import exploreImg2 from "../../../assets/explore_images/explore_2.png";
import exploreImg3 from "../../../assets/explore_images/explore_3.png";
import exploreImg4 from "../../../assets/explore_images/explore_4.png";
import testimonialVid1 from "../../../assets/testimonial_videos/testimonial_1.mp4";
import testimonialVid2 from "../../../assets/testimonial_videos/testimonial_2.mp4";
import testimonialVid3 from "../../../assets/testimonial_videos/testimonial_3.mp4";
import banner1 from "../../../assets/banner-tiles/banner_1.png";
import banner2 from "../../../assets/banner-tiles/banner_2.png";
import banner3 from "../../../assets/banner-tiles/banner_3.png";
import banner4 from "../../../assets/banner-tiles/banner_4.png";

export const WORD_LIST = [
  "photo album",
  "life story",
  "travel log",
  "personal journal",
  "life coach",
  "dream catcher",
  "bucket list",
  "hall of fame",
  "career path",
  "inspiration",
  "trophy case",
  "happy place",
];

export const EXPLORE_IMAGE_LIST = [
  exploreImg1,
  exploreImg2,
  exploreImg3,
  exploreImg4,
];

export const TESTIMONIAL_VIDEO_LIST = [
  testimonialVid1,
  testimonialVid2,
  testimonialVid3,
];

export const BANNER_TILE_LIST = [banner1, banner2, banner3, banner4];
