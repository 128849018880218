import VVButton from "../../../components/buttons/vv-button";
import { classNames } from "../../../shared/utils/tailwind.utils";
import { QuizardEntity } from "../entities/quizard.entity";

interface Props {
  quizard: QuizardEntity;
  onClickView: (quizard: QuizardEntity) => void;
}

const types: any = {
  New: "text-green-700 bg-green-50 ring-green-600/20",
  Featured: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
};

const statuses: any = {
  New: "text-green-700 bg-green-50 ring-green-600/20",
  Started: "text-gray-600 bg-gray-50 ring-gray-500/10",
  "In progress": "text-gray-600 bg-gray-50 ring-gray-500/10",
  Complete: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
};

export const QuizardListItem = ({ quizard, onClickView }: Props) => {
  const { id, title, type, status, description, imageUrl } = quizard;
  return (
    <li
      key={id}
      className="flex items-center justify-between gap-x-6 py-5 px-4 rounded-lg border border-gray-200 bg-white"
    >
      <div className="min-w-0 flex gap-3">
        <img
          className="h-40 w-40 flex-none rounded-lg bg-gray-50"
          src={imageUrl}
          alt=""
        />
        <div className="py-2">
          <div className="flex items-start gap-x-3">
            <p className="text-2xl font-semibold leading-6 text-gray-900">
              {title}
              {type && (
                <span
                  className={classNames(
                    types[type],
                    "mx-2 rounded-md mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                  )}
                >
                  {type}
                </span>
              )}
            </p>
          </div>
          <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
            <p className="text-xl">{description}</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center gap-y-2">
        <p
          className={classNames(
            statuses[status],
            "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
          )}
        >
          {status}
        </p>
        <VVButton
          className="bg-indigo-600 text-white whitespace-nowrap"
          onClick={() => onClickView(quizard)}
        >
          <p>
            View Quizard
            <span className="sr-only">, {title}</span>
          </p>
        </VVButton>
      </div>
    </li>
  );
};
