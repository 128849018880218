import quizard_cover from "../../../assets/quizard/quizard-cover.png";
import { QuizardList } from "../components/quizard-list.component";

export const QuizardsPage = () => {
  return (
    <>
      <section>
        <img className="bg-[#6a826b] w-auto" src={quizard_cover} alt="" />
      </section>
      <section className="mt-6 container mx-auto px-4">
        <p className="text-xl">
          Your life story has several core facets and Quizards are the perfect
          tool to help you fill them in. Below you will find an ever-changing
          list of life topics. Try a Quizard to give you the hints, tips, and
          resources to color in the pages of your amazing life.
        </p>
      </section>
      <QuizardList />
    </>
  );
};
