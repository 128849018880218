import abc_news_logo from "../../../assets/as_seen_on_logos/abc_news.png";
import amazon_logo from "../../../assets/as_seen_on_logos/amazon.png";
import star_logo from "../../../assets/as_seen_on_logos/star.png";
import today_logo from "../../../assets/as_seen_on_logos/today.png";
import wired_logo from "../../../assets/as_seen_on_logos/wired.png";

export default function AsSeenOnComponent() {
  return (
    <section className="bg-[#f5f3f0] pt-6">
      <div className="container mx-auto">
        <h1 className="text-2xl mx-6">As seen on</h1>
        <div className="grid grid-cols-2 md:grid-cols-5 md:gap-4 justify-items-center">
          <img src={today_logo} alt="today" className="h-16 lg:h-20 my-10" />
          <img src={wired_logo} alt="today" className="h-16 xl:h-20 my-10" />
          <img src={abc_news_logo} alt="today" className="h-16 xl:h-20 my-10" />
          <img src={amazon_logo} alt="today" className="h-16 xl:h-20 my-10" />
          <img src={star_logo} alt="today" className="h-16 xl:h-20 my-10" />
        </div>
      </div>
    </section>
  );
}
