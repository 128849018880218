export default function VVButton({
  onClick,
  type,
  className,
  disabled = false,
  children,
}: {
  onClick?: (event?: any) => void;
  type?: "button" | "submit" | "reset" | undefined;
  className?: string;
  disabled?: boolean;
  children: JSX.Element | string;
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`px-5 py-2 rounded-full text-sm md:text-base hover:scale-105 ease-in duration-100 ${
        className ?? ""
      }`}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
