import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, Cog8ToothIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useUserStore } from "../../modules/user/store/user.store";

const navItems = [
  {
    name: "My Lifeline",
    href: "/user",
    icon: Cog8ToothIcon,
  },
  {
    name: "My Album",
    href: "/user/albums/my-album",
    icon: Cog8ToothIcon,
  },
];

export default function ViewsNav() {
  const user = useUserStore((state) => state.user);
  return (
    <Popover className="relative">
      <Popover.Button className="inline-flex items-center gap-x-1 leading-6 text-white">
        <li>Views</li>
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
          <div className="w-56 shrink rounded-xl bg-white p-4 leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
            {navItems.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="block p-2 hover:text-indigo-600"
              >
                {item.name}
              </Link>
            ))}
            {user?.quizards.map((item) => (
              <Link
                key={item.title}
                to={`/user/albums/${item.tag}`}
                className="block p-2 hover:text-indigo-600"
              >
                {item.title}
              </Link>
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
