import { Transition } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { ReactElement, useEffect, useState } from "react";
import { TESTIMONIAL_VIDEO_LIST } from "../constants/home.constants";

export default function TestimonialSliderComponent() {
  const [testimonialIndex, setTestimonialIndex] = useState(0);
  const sliderPoints: ReactElement[] = [];
  const [show, setShow] = useState(true);
  const [noDelay, setNoDelay] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 10);
  }, [testimonialIndex]);

  const moveLeft = (force?: boolean) => {
    if (force) {
      setNoDelay(true);
    }
    if (testimonialIndex === 0) {
      setTestimonialIndex(TESTIMONIAL_VIDEO_LIST.length - 1);
    } else {
      setTestimonialIndex((testimonialIndex) => testimonialIndex - 1);
    }
  };

  const moveRight = (force?: boolean) => {
    if (force) {
      setNoDelay(true);
    }
    setTestimonialIndex(
      (testimonialIndex) =>
        (testimonialIndex + 1) % TESTIMONIAL_VIDEO_LIST.length
    );
  };

  for (let i = 0; i < TESTIMONIAL_VIDEO_LIST.length; i++) {
    sliderPoints.push(
      <div
        key={i}
        className={`h-3 w-3 mx-1 rounded-full ${
          i !== testimonialIndex ? "bg-slate-500" : "bg-white"
        }`}
      ></div>
    );
  }

  return (
    <section className="relative overflow-hidden">
      <Transition
        show={show}
        appear={true}
        enter="transition-opacity ease-in-out duration-1000"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave={`transition-opacity ease-in-out duration-1000 ${
          noDelay ? "" : "delay-[5000ms]"
        } `}
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterEnter={() => {
          setShow(false);
        }}
        afterLeave={() => {
          moveRight();
          setNoDelay(false);
        }}
      >
        <video
          key={testimonialIndex}
          autoPlay
          muted
          className="absolute min-h-full object-cover"
        >
          <source
            src={`${TESTIMONIAL_VIDEO_LIST[testimonialIndex]}`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Transition>

      <div className="relative h-[300px] md:h-[600px] w-full flex items-center justify-between">
        <button
          className="h-full bg-slate-800/50 p-2 md:p-6"
          onClick={() => moveLeft(true)}
        >
          <ChevronLeftIcon className="h-10 w-10 text-white"></ChevronLeftIcon>
        </button>
        <div className="flex bg-slate-600/80 p-2 rounded-full self-end mb-5">
          {sliderPoints}
        </div>
        <button
          className="h-full bg-slate-800/50 p-2 md:p-6"
          onClick={() => moveRight(true)}
        >
          <ChevronRightIcon className="h-10 w-10 text-white"></ChevronRightIcon>
        </button>
      </div>
    </section>
  );
}
