import { BANNER_TILE_LIST } from "../constants/home.constants";

export default function SquareTilesComponent() {
  const squareTile = (src: string, alt: string) => {
    return (
      <div className="bg-white text-center hover:scale-105 ease-in duration-200">
        <img src={src} alt={alt} />
      </div>
    );
  };

  return (
    <section className="bg-black mt-10">
      <div className="mx-auto w-0 h-0 border-x-[60px] border-x-transparent border-t-[60px] border-t-white"></div>
      <h1 className="text-white text-3xl md:text-5xl italic text-center py-10">
        Life is a game in which happiness is the goal
      </h1>
      <div className="flex flex-col md:grid md:grid-cols-2 gap-6 md:gap-10 p-6 md:p-10">
        {squareTile(BANNER_TILE_LIST[0], "banner 1")}
        {squareTile(BANNER_TILE_LIST[1], "banner 2")}
        {squareTile(BANNER_TILE_LIST[2], "banner 3")}
        {squareTile(BANNER_TILE_LIST[3], "banner 4")}
      </div>
    </section>
  );
}
