import Overlay from "../../../components/overlay";
import introVideo from "../../../assets/intro_video/intro_to_vidavu.mp4";

export default function HeroVideoOverlay({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Overlay open={isOpen} onClose={onClose}>
      <video autoPlay className="w-full object-cover">
        <source src={introVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Overlay>
  );
}
