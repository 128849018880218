import { createJSONStorage, persist } from "zustand/middleware";
import { submitPinApi } from "../../flashboard/api/flashboard.api";
import { FlashboardParamsEntity } from "../../flashboard/entities/flashboard-params.entity";
import { FlashboardParamsModel } from "../../flashboard/models/flashboard-params.model";
import { create } from "zustand";

export enum AuthStatus {
  IDLE = "idle",
  LOGGED_IN = "loggedin",
  LOGGED_OUT = "loggedout",
}

interface AuthState {
  token: string | null;
  status: AuthStatus;
  initial: boolean;
  login: (token: string) => void;
  logout: () => void;
  setInitial: () => void;
  submitPin: (flashboardPin: FlashboardParamsEntity) => void;
}

export const useAuthStore = create(
  persist<AuthState>(
    (set, get) => ({
      status: AuthStatus.IDLE,
      token: null,
      initial: false,
      login: (token: string) => {
        set({ status: AuthStatus.LOGGED_IN, token, initial: true });
      },
      logout: () => {
        set({ status: AuthStatus.LOGGED_OUT, token: null });
      },
      setInitial: () => {
        set({ initial: false });
      },
      submitPin: async (flashboardPin: FlashboardParamsEntity) => {
        try {
          const response = await submitPinApi(
            FlashboardParamsModel.fromEntity(flashboardPin)
          );

          if (response.data.status) {
            set({ status: AuthStatus.LOGGED_OUT, token: null });
          } else {
            set({ status: AuthStatus.IDLE });
          }
        } catch (e) {
          console.error(e);
          set({ status: AuthStatus.IDLE });
        }
      },
    }),
    {
      name: "auth",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
