export default function FormComponent({
  children,
  id,
  label,
  className,
}: {
  children: any;
  id: string;
  label?: string;
  className?: string;
}) {
  return (
    <div className={`text-start ${className}`}>
      {label && (
        <label htmlFor={id} className="text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      {children}
    </div>
  );
}
