import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VVButton from "../../../components/buttons/vv-button";
import NumberInput from "../../../components/form/number-input";
import PasswordInput from "../../../components/form/password-input";
import Logo from "../../../components/logo/logo";
import { FormFunctions, useForm } from "../../../shared/hooks/use-form.hook";
import { forgetPasswordResetApi } from "../apis/auth.api";
import ErrorMessage from "../components/error-message";
import { ForgotPasswordResetEntity } from "../entities/forgot-password-reset.entity";
import { ForgotPasswordResetModel } from "../models/forgot-password-reset.model";
export default function ForgotPasswordResetPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const [errorMessage, setErrorMessge] = useState(null);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    forgetPasswordResetApi(ForgotPasswordResetModel.fromEntity(formFunc.values))
      .then((res) => {
        navigate("/login");
      })
      .catch((e) => {
        setErrorMessge(e.response.data.message);
      });
  };

  const formFunc: FormFunctions<ForgotPasswordResetEntity> = useForm(
    handleSubmit,
    {
      OTP: "",
      newPassword: "",
      confirmNewPassword: "",
      email: email || "",
    }
  );

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo />

        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          Please enter the code sent to your email and a new password
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Or{" "}
          <Link
            to="/login"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Sign In
          </Link>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {errorMessage && (
            <div className="py-2">
              <ErrorMessage message={errorMessage} />
            </div>
          )}
          <form
            className="space-y-6 flex flex-col"
            action="#"
            method="POST"
            onSubmit={handleSubmit}
          >
            <NumberInput
              id="OTP"
              label="Code"
              required
              value={formFunc.values.OTP}
              onChange={formFunc.onChange}
            />
            <PasswordInput
              id="newPassword"
              required
              label="New Password"
              value={formFunc.values.newPassword}
              onChange={formFunc.onChange}
            />
            <PasswordInput
              id="confirmNewPassword"
              required
              label="Confirm New Password"
              value={formFunc.values.confirmNewPassword}
              onChange={formFunc.onChange}
            />

            <VVButton
              type="submit"
              className="bg-indigo-600 self-center text-white"
            >
              Reset password
            </VVButton>
          </form>
        </div>
      </div>
    </div>
  );
}
