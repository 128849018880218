import { useUserStore } from "../../modules/user/store/user.store";
import useWindowDimensions, {
  WIDTH_PREFIX,
} from "../../shared/hooks/get-window-dimensions.hook";

export default function CoverflowSliderComponent({
  onChange,
  startAnimation,
  activeIndex,
  totalLength,
  todayPageIndex = -1,
}: {
  onChange: any;
  startAnimation: boolean;
  activeIndex: number;
  totalLength: number;
  todayPageIndex?: number;
}) {
  const slidePoints = [];
  const { width } = useWindowDimensions();
  const animateLines = 10;

  let divider = Math.ceil(totalLength / 100);
  const user = useUserStore((state) => state.user);

  if (width < WIDTH_PREFIX.sm) {
    divider = divider * 3;
  } else if (width < WIDTH_PREFIX.md) {
    divider = divider * 2;
  }

  function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  const animateClasses: any[] = [];

  for (let i = 1; i <= animateLines; i++) {
    animateClasses.push({
      animationDelay: `${i * 100}ms`,
      animationFillMode: "both",
    });
  }

  for (let i = 0; i < totalLength; i = i + divider) {
    slidePoints.push(
      <div
        className="px-0.5 lg:px-1 group relative"
        key={i}
        onClick={() => onChange(i)}
      >
        <div
          className={`${
            i < todayPageIndex
              ? "border-2"
              : i === todayPageIndex
              ? "border-4"
              : "border"
          } h-6 ${startAnimation && "animate-fall"} ${
            i === activeIndex - (activeIndex % divider)
              ? "border-red-400"
              : "border-slate-500"
          }`}
          style={animateClasses[getRandomInt(0, animateLines)]}
        >
          <span className="absolute hidden group-hover:flex -left-7 -top-2 -translate-y-full px-2 py-1 w-16 bg-[#638a9e] rounded-full justify-center text-white text-sm after:content-[''] after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-[#638a9e]">
            {i + 1}/{totalLength}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center mb-10">
      <h3 className="font-bold text-2xl text-[#638a9e] bg-[#ededed] px-2 py-1">
        {user?.birthYear ?? ""}
      </h3>
      <div className="bg-[#ededed] p-2 flex">{slidePoints}</div>
      <h3 className="font-bold text-2xl text-[#638a9e] bg-[#ededed] p-2 py-1">
        {user?.birthYear ? (parseInt(user?.birthYear) + 100).toString() : ""}
      </h3>
    </div>
  );
}
