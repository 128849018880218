import { Link } from "react-router-dom";

export default function LogoSmall() {
  return (
    <Link to="/">
      <img
        src="https://vidavu-assests-stage.s3.amazonaws.com/assets/logo_image.png"
        className="w-16 md:w-20 h-auto mx-auto"
        alt="logo"
      />
    </Link>
  );
}
