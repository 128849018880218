import CoverflowSliderComponent from "../../../components/coverflow/coverflow-slider.component";
import CoverflowComponent from "../../../components/coverflow/coverflow.component";
import { useAlbumStore } from "../store/album.store";

export default function AlbumCoverflowComponent({
  onChange,
  startAnimation,
}: {
  onChange: (index: number) => void;
  startAnimation: boolean;
}) {
  const events = useAlbumStore((state) => state.lifeEvents);
  const activeIndex = useAlbumStore((state) => state.activeIndex);
  const totalLength = useAlbumStore((state) => state.totalLength);

  return (
    <>
      <CoverflowComponent
        events={events}
        activeIndex={activeIndex}
        onChange={onChange}
        startAnimation={startAnimation}
        isAlbum={true}
      />

      <CoverflowSliderComponent
        startAnimation={startAnimation}
        onChange={onChange}
        activeIndex={activeIndex}
        totalLength={totalLength}
      />
    </>
  );
}
