import { COVER_ART_IMAGES } from "./life-event.constants";

export const COVER_ARTS = [
  {
    id: 0,
    title: "Winner",
    imageUrl: COVER_ART_IMAGES[0],
  },
  {
    id: 1,
    title: "Anniversary",
    imageUrl: COVER_ART_IMAGES[1],
  },
  {
    id: 2,
    title: "Baby Boy",
    imageUrl: COVER_ART_IMAGES[2],
  },
  {
    id: 3,
    title: "Baby Girl",
    imageUrl: COVER_ART_IMAGES[3],
  },
];
