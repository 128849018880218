import { Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

import facebook_icon from "../assets/footer_icons/facebook.png";
import instagram_icon from "../assets/footer_icons/instagram.png";
import linkedin_icon from "../assets/footer_icons/linkedin.png";
import twitter_icon from "../assets/footer_icons/twitter.png";
import youtube_icon from "../assets/footer_icons/youtube.png";

export default function Footer() {
  const [isShowing, setIsShowing] = useState(true);

  const handleFooterOnclick = () => {
    setIsShowing((isShowing) => !isShowing);
  };

  return (
    <footer className="flex flex-col bg-black text-white ">
      <button
        className="self-end bg-slate-700/80 rounded-full p-1 -mt-4 mr-10"
        onClick={() => handleFooterOnclick()}
      >
        {isShowing ? (
          <ChevronDownIcon className="text-white h-8 w-8"></ChevronDownIcon>
        ) : (
          <ChevronUpIcon className="text-white h-8 w-8"></ChevronUpIcon>
        )}
      </button>
      <div className="mx-6 md:container md:mx-auto">
        <Transition
          appear={true}
          show={isShowing}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <h1 className="mt-10 text-4xl font-semibold">Vidavu</h1>
          <div className="mt-10 grid grid-cols-3 gap-4 text-xl font-semibold">
            <p>Company</p>
            <p>Network</p>
            <p>Support</p>
          </div>
          <div className="mt-6 grid grid-cols-3 gap-2 text-lg">
            <p>About Us</p>
            <p>Strategic Partners</p>
            <p>FAQs</p>
            <p>In the News</p>
            <p>Sponsorships</p>
            <p>Help Center</p>
            <p>Investors</p>
            <p>Creators</p>
            <p>Contact Us</p>
            <p>Media Inquiries</p>
            <p>Affiliates</p>
            <p>Privacy Policy</p>
            <p>Careers</p>
            <p>People Matter Foundation</p>
            <p>Terms of Use</p>
          </div>
          <div className="flex items-center my-10">
            <img
              src={facebook_icon}
              alt="facebook"
              className="w-8 h-8 mr-10 md:mr-32"
            />
            <img
              src={twitter_icon}
              alt="facebook"
              className="w-6 h-6 mr-10 md:mr-32"
            />
            <img
              src={youtube_icon}
              alt="facebook"
              className="w-8 h-8 mr-10 md:mr-32"
            />
            <img
              src={linkedin_icon}
              alt="facebook"
              className="w-6 h-6 mr-10 md:mr-32"
            />
            <img
              src={instagram_icon}
              alt="facebook"
              className="w-8 h-8 mr-10 md:mr-32"
            />
          </div>
        </Transition>
        <div className="my-2 flex justify-between">
          <h3>©️ 2023 Vidavu Inc</h3>
          <h3>Privacy Policy | Terms & Conditions</h3>
        </div>
      </div>
    </footer>
  );
}
