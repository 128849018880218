import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { LifeEventEntity } from "../entities/life-event.entity";
import AdditionalMediaEditComponent from "./additional-media-edit.component";

export default function AdditionalMediaCreateComponent({
  lifeEvent,
}: {
  lifeEvent: LifeEventEntity;
}) {
  const [addMediaMode, setAddMediaMode] = useState(false);
  const addMoreInfo = () => {
    setAddMediaMode(true);
  };

  return (
    <div className="mx-auto">
      {addMediaMode ? (
        <AdditionalMediaEditComponent
          additionalMedia={null}
          lifeEvent={lifeEvent}
          onCancel={() => setAddMediaMode(false)}
        />
      ) : (
        <button
          type="button"
          onClick={addMoreInfo}
          className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-4 mb-4 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <PlusCircleIcon className="mx-auto h-12 w-12 text-gray-400" />
          <span className="mt-2 block text-sm font-medium text-gray-900">
            Add more details
          </span>
        </button>
      )}
    </div>
  );
}
