import { useNavigate } from "react-router-dom";
import VVButton from "../../../components/buttons/vv-button";
import ModalSquare from "../../../components/modal-square";
import Overlay from "../../../components/overlay";
import { meApi } from "../../user/apis/user.api";
import { UserModel } from "../../user/models/user.model";
import { useUserStore } from "../../user/store/user.store";
import { startQuizardApi } from "../apis/quizard.api";
import { QuizardEntity } from "../entities/quizard.entity";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  quizard: QuizardEntity;
}

export const QuizardModalComponent = ({ isOpen, onClose, quizard }: Props) => {
  const navigate = useNavigate();
  const setUser = useUserStore((state) => state.setUser);

  const onClickStart = () => {
    startQuizardApi({
      quizardTag: quizard.tag,
    }).then((res) => {
      navigate(`/user/albums/${quizard.tag}`);
      meApi().then((res) => {
        const userData = res.data;
        setUser(new UserModel(userData).toEntity());
      });
    });
  };

  return (
    <Overlay open={isOpen} onClose={onClose}>
      <div className="flex flex-col md:flex-row">
        <ModalSquare>
          {quizard && (
            <video
              style={{
                height: "inherit",
              }}
              width="100%"
              controls
              poster={quizard.imageUrl}
              controlsList="nofullscreen nodownload noremoteplayback noplaybackrate "
            >
              <source
                src={
                  quizard.videoUrl ??
                  "https://xf-prod-site-s3.s3.us-east-2.amazonaws.com/optimized/desktop_mp4/244_JSnTY7Qzko0p2yw2gDbzzHB3iY8a8nxLGx5jaRgu.mp4"
                }
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          )}
        </ModalSquare>
        {quizard && (
          <ModalSquare>
            <div className="flex flex-col h-full justify-center gap-10 px-8">
              <h1 className="text-2xl">{quizard.title}</h1>
              <p>{quizard.description}</p>
              <VVButton
                className="bg-indigo-600 self-center text-white"
                onClick={onClickStart}
              >
                Start Quizard
              </VVButton>

              <div>
                <h3>Useful links</h3>
                <p>Link 1</p>
                <p>Link 2</p>
                <p>Link 3</p>
              </div>
            </div>
          </ModalSquare>
        )}
      </div>
    </Overlay>
  );
};
