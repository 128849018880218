import { TrashIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import VVButton from "../../../components/buttons/vv-button";
import CheckboxInput from "../../../components/form/checkbox-input";
import DateInput from "../../../components/form/date-input";
import Select from "../../../components/form/select";
import TextArea from "../../../components/form/text-area";
import TextInput from "../../../components/form/text-input";
import ModalSquare from "../../../components/modal-square";
import { FormFunctions, useForm } from "../../../shared/hooks/use-form.hook";
import { upperCase } from "../../../shared/utils/helper.utils";
import { CATEGORY_LIST } from "../constants/life-event.constants";
import { LifeEventEntity } from "../entities/life-event.entity";

export default function EditLifeEventComponent({
  formValues,
  setFormValues,
  handleSubmit,
  onClose,
  lifeEvent,
  deleteLifeEvent,
}: {
  formValues: LifeEventEntity | undefined;
  setFormValues: (lifeEvent: LifeEventEntity) => void;
  handleSubmit: () => void;
  onClose: () => void;
  lifeEvent: LifeEventEntity;
  deleteLifeEvent: (id: number) => void;
}) {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const formData: FormFunctions<LifeEventEntity> = useForm(
    handleSubmit,
    formValues ?? lifeEvent
  );

  const customOnChange = (e: any) => {
    formData.onChange(e);
    setFormValues({ ...formData.values, [e.target.name]: e.target.value });
  };

  const customOnCheck = (e: any) => {
    formData.onCheck(e);
    setFormValues({ ...formData.values, [e.target.name]: e.target.checked });
  };

  const deletePage = (e: any) => {
    e.preventDefault();
    deleteLifeEvent(lifeEvent.id);
    onClose();
  };

  const openConfirmDelete = (e: any) => {
    e.preventDefault();
    setConfirmDelete(true);
  };

  const cancelDelete = (e: any) => {
    e.preventDefault();
    setConfirmDelete(false);
  };

  const toUppercase = (e: any) => {
    e.target.value = upperCase(e.target.value);
  };

  return (
    <>
      {confirmDelete ? (
        <ModalSquare>
          <div className="flex flex-col h-full justify-center">
            <h1>You are about to delete this life event. Are you sure?</h1>
            <div className="flex justify-center p-2">
              <VVButton
                className="bg-indigo-600 text-white mx-1"
                onClick={(e) => cancelDelete(e)}
              >
                Cancel
              </VVButton>
              <VVButton
                className="bg-red-600 text-white mx-1"
                onClick={(e) => deletePage(e)}
              >
                Delete
              </VVButton>
            </div>
          </div>
        </ModalSquare>
      ) : (
        <ModalSquare className="px-6 overflow-scroll">
          <form onSubmit={formData.onSubmit} className="flex flex-col my-4">
            <Select
              className="p-2"
              id="category"
              label="Select a general life category for this page"
              options={CATEGORY_LIST}
              onChange={customOnChange}
              value={formData.values.category}
            />
            <TextInput
              className="p-2"
              id="title"
              label="Headline"
              value={formData.values.title}
              onChange={customOnChange}
              onInput={toUppercase}
              required
              placeholder="HEADLINE GOES HERE (up to 40 characters)"
            />
            <TextArea
              className="p-2"
              id="description"
              label="Description"
              value={formData.values.description}
              onChange={customOnChange}
              maxLength={100}
              required
              placeholder="Enter your page description here. (up to 100 characters)"
            />
            <div className="flex flex-col lg:flex-row">
              <DateInput
                className="basis-full p-2"
                id="startDate"
                label="Start Date"
                value={formData.values.startDate}
                onChange={customOnChange}
                required
              />
              <DateInput
                className="basis-full p-2"
                id="endDate"
                label="End Date"
                value={formData.values.endDate}
                onChange={customOnChange}
              />
            </div>

            <CheckboxInput
              className="p-2"
              id="isDateRangeVerified"
              checked={formData.values.isDateRangeVerified}
              onChange={customOnCheck}
              label="Check this box if you are certain about the date(s). This will help better organize your life line as you create it. You can always update them later"
            />
            <TextInput
              className="p-2"
              id="customTags"
              label="Tags"
              value={formData.values.customTags}
              onChange={customOnChange}
              placeholder="Enter custom hashtags here to help search. (commas to separate)"
            />
            <div className="flex justify-center p-2">
              <VVButton
                type="submit"
                className={`${
                  formData.changed ? "bg-indigo-600" : "bg-slate-500"
                } text-white mx-1`}
                disabled={!formData.changed}
              >
                Submit
              </VVButton>
              <VVButton
                className="bg-red-600 text-white mx-1"
                onClick={(e) => openConfirmDelete(e)}
              >
                <TrashIcon className="h-6"></TrashIcon>
              </VVButton>
            </div>
          </form>
        </ModalSquare>
      )}
    </>
  );
}
