import { QuizardEntity } from "../entities/quizard.entity";

export interface IQuizardModel {
  id: number;
  tag: string;
  title: string;
  type?: string;
  status: string;
  description: string;
  imageUrl: string;
  videoUrl: string;
}

export class QuizardModel {
  id: number;
  tag: string;
  title: string;
  type?: string;
  status: string;
  description: string;
  imageUrl: string;
  videoUrl: string;

  constructor(data: IQuizardModel) {
    this.id = data.id;
    this.title = data.title;
    this.tag = data.tag;
    this.status = data.status;
    this.description = data.description;
    this.imageUrl = data.imageUrl;
    this.videoUrl = data.videoUrl;
  }

  toEntity(): QuizardEntity {
    return {
      ...this,
    };
  }
}
