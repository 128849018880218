import { create } from "zustand";
import { UserEntity } from "../entities/user.entity";

interface UserState {
  user: UserEntity | null;
  setUser: (user: UserEntity) => void;
}

export const useUserStore = create<UserState>((set, get) => ({
  user: null,
  setUser: (user: UserEntity) => {
    set({ user });
  },
}));
