export default function VVHeroButton({
  label,
  onClick,
  className,
  postIcon,
}: {
  label: string;
  onClick: () => void;
  className?: string;
  postIcon?: JSX.Element;
}) {
  return (
    <button
      onClick={onClick}
      className={`p-4 rounded-full text-white bg-[#00aeef] hover:scale-105 ease-in duration-100 ${
        className ?? ""
      }`}
    >
      <div className="flex">
        <h1 className="mx-2 text-xl xl:text-3xl">{label}</h1>
        {postIcon}
      </div>
    </button>
  );
}
