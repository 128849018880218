import React from "react";

export interface InputParams extends FormParams {
  type: string;
}

export interface FormParams {
  id: string;
  label: string;
  required?: boolean;
  value?: any;
  onChange?: any;
  onInput?: any;
  autoComplete?: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
}

export default function Input(params: InputParams) {
  return (
    <div className="mt-1">
      <input
        type={params.type}
        name={params.id}
        id={params.id}
        value={params.value}
        onChange={params.onChange}
        autoComplete={params.autoComplete}
        required={params.required}
        placeholder={params.placeholder}
        onInput={params.onInput}
        disabled={params.disabled}
        className="w-full outline-0 rounded-t-md border-0 border-b-2 border-gray-400 focus:border-indigo-500 focus:ring-0 text-lg lg:text-xl"
      />
    </div>
  );
}
