import { CATEGORY_DEFAULT } from "../constants/life-event.constants";

export enum LifeEventTypeEnum {
  past = "past",
  birth = "birth",
  end = "end",
  user = "user",
  future = "future",
  cover = "cover",
}

interface ILifeEventEntity {
  id: number;
  title: string;
  startDate: string;
  endDate?: string;
  description: string;
  imageName?: string;
  imageUrl?: string;
  thumbnail?: string;
  customTags?: string;
  type?: LifeEventTypeEnum;
  category: string;
  isDateRangeVerified: boolean;
  isAdditionalMedia: boolean;
  albumTag?: string;
}

export class LifeEventEntity {
  id: number;
  title: string;
  startDate: string;
  endDate?: string;
  description: string;
  imageName?: string;
  imageUrl?: string;
  thumbnail?: string;
  customTags?: string;
  type?: LifeEventTypeEnum;
  category: string;
  isDateRangeVerified: boolean;
  isAdditionalMedia: boolean;
  albumTag?: string;

  constructor(data: ILifeEventEntity) {
    this.id = data.id;
    this.title = data.title;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.description = data.description;
    this.imageUrl = data.imageUrl;
    this.imageName = data.imageName;
    this.thumbnail = data.thumbnail;
    this.customTags = data.customTags;
    this.type = data.type;
    this.category = data.category;
    this.isDateRangeVerified = data.isDateRangeVerified;
    this.isAdditionalMedia = data.isAdditionalMedia;
    this.albumTag = data.albumTag;
  }

  static createLoadingLifeEvent = (index: number): LifeEventEntity =>
    new LifeEventEntity({
      id: -index,
      title: "Loading...",
      startDate: `2022-04-11`,
      description: "",
      imageName: "",
      thumbnail: "",
      category: CATEGORY_DEFAULT,
      isDateRangeVerified: false,
      isAdditionalMedia: false,
      albumTag: "",
    });

  static createEmptyLifeEvent = (albumTag?: string): LifeEventEntity =>
    new LifeEventEntity({
      id: -1,
      title: "",
      startDate: "",
      endDate: "",
      description: "",
      imageName: "",
      thumbnail: "",
      category: CATEGORY_DEFAULT,
      isDateRangeVerified: false,
      isAdditionalMedia: false,
      albumTag,
    });
}
