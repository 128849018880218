import { GlobeAmericasIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import VVHeroButton from "../../../components/buttons/vv-hero-button";
import { useIsVisible } from "../../../shared/hooks/use-is-visible.hook";
import { EXPLORE_IMAGE_LIST } from "../constants/home.constants";

export default function MobileSliderComponent() {
  const [exploreIndex, setExploreIndex] = useState(0);
  const ref = useRef<HTMLInputElement>(null);
  const isVisible = useIsVisible(ref);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [visibleScrollPosition, setVisibleScrollPosition] = useState(-1);
  const [imagePosition, setImagePosition] = useState(-500);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isVisible && visibleScrollPosition < 0) {
      setVisibleScrollPosition(scrollPosition);
    }

    if (visibleScrollPosition > 0 && scrollPosition > visibleScrollPosition) {
      const position = -500 + scrollPosition - visibleScrollPosition;

      if (position < 0) setImagePosition(position);
    }
  }, [isVisible, visibleScrollPosition, scrollPosition]);

  return (
    <section
      className="flex flex-col-reverse md:flex-row mx-6 xl:mx-auto xl:container mt-10 md:mt-0"
      ref={ref}
    >
      <img
        src={EXPLORE_IMAGE_LIST[exploreIndex]}
        alt="hero"
        className="hidden md:flex md:h-[300px] lg:h-[400px] xl:h-[500px] w-auto z-10 -mt-16"
        style={{
          transform: `translateX(${imagePosition}px)`,
        }}
      />

      <div className="flex flex-col mx-6 md:ml-10 md:mt-10">
        <h1 className="text-4xl xl:text-6xl text-[#8a8690]">The views are</h1>
        <h1 className="text-4xl xl:text-6xl text-[#8a8690]">
          simply spectacular.
        </h1>
        <p className="mt-10 text-xl xl:text-3xl">
          See your life like never before: the dreaded school pictures, your
          career path, the places you've been and much more.
        </p>
        <img
          src={EXPLORE_IMAGE_LIST[exploreIndex]}
          alt="hero"
          className="mt-4 md:hidden w-auto z-10"
          style={{
            transform: `translateX(${imagePosition}px)`,
          }}
        />
        <div className="text-center mt-4 md:mt-10">
          <VVHeroButton
            label="Explore"
            onClick={() => {
              setExploreIndex(
                (exploreIndex) => (exploreIndex + 1) % EXPLORE_IMAGE_LIST.length
              );
            }}
            postIcon={
              <GlobeAmericasIcon className="h-8 xl:h-10 w-8 xl:w-10 text-white"></GlobeAmericasIcon>
            }
          ></VVHeroButton>
        </div>
      </div>
    </section>
  );
}
