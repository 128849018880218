import { http } from "../../../shared/services/http.service";
import { StartQuizardModel } from "../models/start-quizard.model";

export const getQuizardsApi = async (page: number) =>
  http({
    url: `quizard`,
    method: "GET",
    auth: true,
    params: {
      page,
    },
  });

export const startQuizardApi = async (data: StartQuizardModel) =>
  http({ url: "quizard/start", method: "POST", auth: true, data });
