import todayPageBanner1 from "../../../assets/today_page_banners/today_banner_1.png";
import todayPageBanner2 from "../../../assets/today_page_banners/today_banner_2.png";
import todayPageBanner3 from "../../../assets/today_page_banners/today_banner_3.png";

import instruction_image from "../../../assets/images/instruction.png";

import filter_none from "../../../assets/filters/filter-none.png";
import filter_bnw from "../../../assets/filters/filter-blacknwhite.png";
import filter_colorize from "../../../assets/filters/filter-colorize.png";
import filter_desaturate from "../../../assets/filters/filter-desaturate.png";
import filter_brighten from "../../../assets/filters/filter-brighten.png";
import filter_contrast from "../../../assets/filters/filter-contrast.png";
import filter_darken from "../../../assets/filters/filter-darken.png";

import coverArt1 from "../../../assets/cover-arts/cover-art-1.png";
import coverArt2 from "../../../assets/cover-arts/cover-art-2.png";
import coverArt3 from "../../../assets/cover-arts/cover-art-3.png";
import coverArt4 from "../../../assets/cover-arts/cover-art-4.png";

import instructions_your_photo from "../../../assets/instructions/instructions-your-photo.png";
import instructions_digital_image from "../../../assets/instructions/instructions-digital-image.png";
import instructions_frames from "../../../assets/instructions/instructions-frames.png";
import instructions_cover_art from "../../../assets/instructions/instructions-cover-art.png";

import frame_general_circle from "../../../assets/frames/frame-general-circle.png";
import frame_general_construction from "../../../assets/frames/frame-general-construction.png";
import frame_general_life from "../../../assets/frames/frame-general-life.png";
import frame_graduation from "../../../assets/frames/frame-graduation.png";
import frame_halloween from "../../../assets/frames/frame-halloween.png";
import frame_love from "../../../assets/frames/frame-love.png";
import frame_wedding from "../../../assets/frames/frame-wedding.png";

import digital_image from "../../../assets/cover-image-styles/digital_image.png";
import photo_frame from "../../../assets/cover-image-styles/photo_frame.png";
import vidavu_cover from "../../../assets/cover-image-styles/vidavu_cover.png";
import your_photo from "../../../assets/cover-image-styles/your_photo.png";

export const TODAY_PAGE_BANNERS = [
  todayPageBanner1,
  todayPageBanner2,
  todayPageBanner3,
];

export const CATEGORY_LIST = [
  { id: "EDUCATION", value: "Education" },
  { id: "PLACE", value: "Home / Places Lived" },
  { id: "INTEREST", value: "Interests & Hobbies" },
  { id: "EVENT", value: "Life Events (Big & small)" },
  { id: "RELATIONSHIP", value: "Relationships / Family" },
  { id: "TRAVEL", value: "Travel" },
  { id: "WORK", value: "Work / Career" },
  { id: "ELSE", value: "Everything Else" },
];

export const CATEGORY_DEFAULT = "EVENT";

export const INSTRUCTION_IMAGE = instruction_image;

export const FILTERS = {
  NONE: filter_none,
  BLACK_AND_WHITE: filter_bnw,
  COLORIZE: filter_colorize,
  DESATURATE: filter_desaturate,
  BRIGHTEN: filter_brighten,
  CONTRAST: filter_contrast,
  DARKEN: filter_darken,
};

export const COVER_ART_IMAGES = [coverArt1, coverArt2, coverArt3, coverArt4];

export const INSTRUCTIONS_IMAGE = {
  YOUR_PHOTO: instructions_your_photo,
  DIGITAL_IMAGE: instructions_digital_image,
  FRAMES: instructions_frames,
  COVER_ART: instructions_cover_art,
};

export const FRAME_IMAGE = {
  GENERAL_CIRCLE: frame_general_circle,
  GENERAL_CONSTRUCTION: frame_general_construction,
  GENERAL_LIFE: frame_general_life,
  GRADUATION: frame_graduation,
  HALLOWEEN: frame_halloween,
  LOVE: frame_love,
  WEDDING: frame_wedding,
};

export const COVER_IMAGE_STYLES = [
  {
    id: 0,
    title: "Your Photo",
    image: your_photo,
  },
  {
    id: 1,
    title: "Digital Image",
    image: digital_image,
  },
  {
    id: 2,
    title: "Photo + Frame",
    image: photo_frame,
  },
  {
    id: 3,
    title: "Vidavu Cover",
    image: vidavu_cover,
  },
];
