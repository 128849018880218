import {
  AdditionalMediaEntity,
  MediaType,
} from "../entities/additional-media.entity";

interface IAdditionalMediaModel {
  id?: number;
  pageId: number;
  description: string;
  url: string;
  type: MediaType;
  dateTime: string;
  fileName: string;
}

export interface CreateAdditionalMediaModel {
  pageId: number;
  description: string;
  fileName: string;
}

export class AdditionalMediaModel {
  id?: number;
  pageId: number;
  description: string;
  url: string;
  type: MediaType;
  dateTime: string;
  fileName: string;

  constructor(data: IAdditionalMediaModel) {
    this.id = data.id;
    this.description = data.description;
    this.url = data.url;
    this.type = data.type;
    this.pageId = data.pageId;
    this.dateTime = data.dateTime;
    this.fileName = data.fileName;
  }

  toEntity(): AdditionalMediaEntity {
    return {
      id: this.id,
      pageId: this.pageId,
      description: this.description,
      mediaType: this.type,
      mediaUrl: this.url,
      date: this.dateTime.substring(0, 10),
      fileName: this.fileName,
    };
  }

  static fromEntity(entity: AdditionalMediaEntity): AdditionalMediaModel {
    return new AdditionalMediaModel({
      id: entity.id,
      description: entity.description,
      url: entity.mediaUrl,
      type: entity.mediaType,
      pageId: entity.pageId,
      fileName: entity.fileName,
      dateTime: entity.date,
    });
  }
}
