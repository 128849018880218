import { QuizardLink } from "../../quizards/models/quizard-link.model";
import { UserEntity } from "../entities/user.entity";

interface IUserModel {
  id: string;
  firstName: string;
  lastName: string;
  birthYear: string;
  email: string;
  birthDay: string;
  profilePicture: string;
  profilePictureName: string;
  quizards: QuizardLink[];
}

export class UserModel {
  id: string;
  firstName: string;
  lastName: string;
  birthYear: string;
  email: string;
  birthDay: string;
  profilePicture: string;
  profilePictureName: string;
  quizards: QuizardLink[];

  constructor(user: IUserModel) {
    this.id = user.id;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.birthYear = user.birthYear;
    this.email = user.email;
    this.birthDay = user.birthDay;
    this.profilePicture =
      user.profilePicture === ""
        ? "https://vidavu-assests-stage.s3.amazonaws.com/assets/profile-image-default.jpg"
        : user.profilePicture;
    this.profilePictureName = user.profilePictureName;
    this.quizards = user.quizards;
  }

  toEntity(): UserEntity {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      birthYear: this.birthYear,
      email: this.email,
      dob: this.birthDay,
      profilePicture: this.profilePicture,
      profilePictureName: this.profilePictureName,
      quizards: this.quizards,
    };
  }
}
