import { useEffect, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import VVButton from "../../../components/buttons/vv-button";
import DateInput from "../../../components/form/date-input";
import EmailInput from "../../../components/form/email-input";
import TextInput from "../../../components/form/text-input";
import { FormFunctions, useForm } from "../../../shared/hooks/use-form.hook";
import {
  FileUploadCategory,
  uploadFile,
} from "../../../shared/services/file-upload.service";
import { updateUserApi } from "../apis/user.api";
import { UserEntity } from "../entities/user.entity";
import { UpdateUserModel } from "../models/update-user.model";
import { UserModel } from "../models/user.model";
import { useUserStore } from "../store/user.store";

export default function ProfilePage() {
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);
  const [imgFile, setImgFile] = useState<File | null>(null);
  const [preview, setPreview] = useState(
    "https://vidavu-assests-stage.s3.amazonaws.com/assets/profile-image-default.jpg"
  );

  useEffect(() => {
    if (user?.profilePicture) {
      setPreview(user?.profilePicture);
    }
  }, [user]);

  const [editor, setEditor] = useState<AvatarEditor | null>(null);

  const setEditorRef = (editor: AvatarEditor) => {
    setEditor(editor);
  };

  const onChange = (e: any) => {
    createImage(e.target.files[0]);
  };

  const createImage = (file: any) => {
    if (file) {
      createPreview(file);
      setImgFile(file);
    }
  };

  const createPreview = (file: File) => {
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let profileRequest = formFunc.values;

    if (editor) {
      let fileName: string;
      let file: File;
      let canvas = editor.getImageScaledToCanvas();
      let ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(canvas, 0, 0);
      }
      canvas.toBlob(async (blob: any) => {
        file = new File([blob], imgFile!.name, { type: imgFile!.type });
        // setFileToUpload(file);
        createPreview(file);
        setImgFile(null);

        fileName = await uploadFile(file, FileUploadCategory.PROFILE);
        profileRequest = {
          ...profileRequest,
          profilePictureName: fileName,
        };

        updateUserApi(UpdateUserModel.fromEntity(profileRequest)).then(
          (res) => {
            setUser(new UserModel(res.data).toEntity());
          }
        );
      }, imgFile!.type);
    } else {
      updateUserApi(UpdateUserModel.fromEntity(profileRequest)).then((res) => {
        setUser(new UserModel(res.data).toEntity());
      });
    }
  };

  const initialState = {
    id: "",
    firstName: "",
    lastName: "",
    birthYear: "",
    email: "",
    profilePicture: "",
    dob: "",
  };

  const formFunc: FormFunctions<UserEntity> = useForm(
    handleSubmit,
    initialState
  );

  useEffect(() => {
    if (user) {
      formFunc.onLoad({ ...user, dob: user.dob ?? `${user.birthYear}-01-01` });
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container mx-auto flex flex-col md:flex-row justify-center md:pt-10">
      <div className="mx-auto md:mx-2">
        {!imgFile ? (
          <img
            className="my-5"
            src={preview}
            width={200}
            height={200}
            alt="profile"
          />
        ) : (
          <div className="my-5">
            <AvatarEditor
              ref={setEditorRef}
              image={imgFile!}
              width={200}
              height={200}
              border={0}
              scale={1}
              rotate={0}
            />
          </div>
        )}

        <label className="bg-slate-200 rounded-sm p-2">
          <input className="hidden" type="file" onChange={onChange} />
          <span>Change Image</span>
        </label>
        <h1 className="text-4xl">
          {user?.firstName} {user?.lastName}
        </h1>
      </div>

      <form
        className="space-y-6 mt-4 mx-4"
        action="#"
        method="POST"
        onSubmit={handleSubmit}
      >
        <TextInput
          id="firstName"
          label="First Name"
          required
          value={formFunc.values.firstName}
          onChange={formFunc.onChange}
        />
        <TextInput
          id="lastName"
          label="Last Name"
          required
          value={formFunc.values.lastName}
          onChange={formFunc.onChange}
        />
        <DateInput
          id="dob"
          label="Date of Birth"
          required
          value={formFunc.values.dob}
          onChange={formFunc.onChange}
        />
        <EmailInput
          id="email"
          label="Email address"
          required
          value={formFunc.values.email}
          onChange={formFunc.onChange}
        />

        <VVButton type="submit" className="bg-indigo-600 text-white">
          Save
        </VVButton>
      </form>
    </div>
  );
}
