import { useEffect, useRef, useState } from "react";
import AdditionalMediaCreateComponent from "./additional-media-create.component";
import AdditionalMediaViewComponent from "./additional-media-view.component";
import { AdditionalMediaEntity } from "../entities/additional-media.entity";
import { AdditionalMediaModel } from "../models/additional-media.model";
import { getMediaApi } from "../apis/additional-media.api";
import { LifeEventEntity } from "../entities/life-event.entity";

export default function AdditionalMediaComponent({
  lifeEvent,
}: {
  lifeEvent: LifeEventEntity;
}) {
  const initEffect = useRef<boolean>(false);
  const [additionalMedias, setAdditionalMedias] = useState<
    AdditionalMediaEntity[]
  >([]);

  useEffect(() => {
    if (initEffect.current === false) {
      if (lifeEvent.isAdditionalMedia) {
        getMediaApi(lifeEvent.id).then((res) => {
          if (res.data.length > 0) {
            const medias = res.data.map((media: any) =>
              new AdditionalMediaModel(media).toEntity()
            );
            setAdditionalMedias(medias);
          }
        });
      }
    }
    return () => {
      initEffect.current = true;
    };
  }, [lifeEvent]);
  return (
    <>
      <div className="flex flex-col">
        {additionalMedias?.length > 0 &&
          additionalMedias?.map((additionalMedia) => (
            <div key={additionalMedia.id}>
              <AdditionalMediaViewComponent
                additionalMedia={additionalMedia}
                lifeEvent={lifeEvent}
              />
              <div className="py-4"></div>
            </div>
          ))}
      </div>
      <AdditionalMediaCreateComponent lifeEvent={lifeEvent} />
    </>
  );
}
