import { useState } from "react";
import VVButton from "../../../components/buttons/vv-button";
import TextArea from "../../../components/form/text-area";
import { FormFunctions, useForm } from "../../../shared/hooks/use-form.hook";
import {
  FileUploadCategory,
  uploadFile,
} from "../../../shared/services/file-upload.service";
import { createMediaApi } from "../apis/additional-media.api";
import { AdditionalMediaEntity } from "../entities/additional-media.entity";
import { LifeEventEntity } from "../entities/life-event.entity";

export default function AdditionalMediaEditComponent({
  additionalMedia,
  lifeEvent,
  onCancel,
}: {
  additionalMedia: AdditionalMediaEntity | null;
  lifeEvent: LifeEventEntity;
  onCancel: () => void;
}) {
  const [preview, setPreview] = useState(
    additionalMedia
      ? additionalMedia.mediaUrl
      : "https://swiperjs.com/demos/images/nature-2.jpg"
  );
  const onChange = (e: any) => {
    createImage(e.target.files[0]);
  };

  const createImage = (file: any) => {
    if (file) {
      createPreview(file);
      setImgFile(file);
    }
  };

  const createPreview = (file: File) => {
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
  };

  const [imgFile, setImgFile] = useState<File | null>(null);

  const handleSubmit = async () => {
    // setAddMediaMode(false);
    try {
      if (imgFile) {
        const fileName = await uploadFile(
          imgFile,
          FileUploadCategory.ADDITIONAL_MEDIA
        );
        await createMediaApi({
          pageId: lifeEvent.id,
          description: formFunc.values.description,
          fileName,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initialState = additionalMedia ?? {
    id: 0,
    mediaType: "",
    mediaUrl: "",
    date: "",
    description: "",
  };

  const formFunc: FormFunctions<AdditionalMediaEntity> = useForm(
    handleSubmit,
    initialState
  );

  return (
    <div className="rounded-lg border border-gray-200">
      <img
        src={preview}
        alt="additional content"
        className="max-w-[400px] max-h-[400px] mx-auto my-4"
      />
      <label className="bg-slate-200 rounded-sm p-2">
        <span>Change image</span>
        <input
          className="hidden"
          type="file"
          accept=".jpg, .png, .jpeg |image/*"
          onChange={onChange}
        />
      </label>
      <form
        className="space-y-6 mt-4 mx-4"
        action="#"
        method="POST"
        onSubmit={formFunc.onSubmit}
      >
        <TextArea
          id="description"
          label="Description"
          value={formFunc.values.description}
          onChange={formFunc.onChange}
          maxLength={100}
          required
        />
        <div className="flex justify-center py-5">
          <VVButton
            type="button"
            onClick={() => onCancel()}
            className="bg-slate-300 self-center"
          >
            Cancel
          </VVButton>
          <div className="p-2"></div>
          <VVButton
            className="bg-indigo-600 self-center text-white"
            type="submit"
          >
            Save
          </VVButton>
        </div>
      </form>
    </div>
  );
}
