import { useNavigate } from "react-router-dom";
import Overlay from "../../../components/overlay";
import ModalSquare from "../../../components/modal-square";
import { REGISTER_VIDEO } from "../constants/register.constants";
import LogoSmall from "../../../components/logo/logo-small";

export default function RegisterCompleteOverlay() {
  const navigate = useNavigate();

  return (
    <Overlay
      open={true}
      onClose={() => {
        navigate("/");
      }}
    >
      <div className="flex flex-col md:flex-row">
        <ModalSquare>
          <video autoPlay loop muted className="">
            <source src={REGISTER_VIDEO} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </ModalSquare>
        <ModalSquare className="overflow-scroll">
          <div className="flex min-h-full flex-col justify-center py-4 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <LogoSmall />

              <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900">
                Thank You for Registering
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                We have sent a verification email to you. Please check your
                email inbox.
              </p>
            </div>

            <div className="mt-8"></div>
          </div>
        </ModalSquare>
      </div>
    </Overlay>
  );
}
