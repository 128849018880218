import { http } from "../../../shared/services/http.service";
import { CreateAdditionalMediaModel } from "../models/additional-media.model";

export const getMediaApi = async (page: number) =>
  http({
    url: "additional-media",
    method: "GET",
    auth: true,
    params: {
      page,
    },
  });

export const createMediaApi = async (data: CreateAdditionalMediaModel) =>
  http({ url: "additional-media", method: "POST", auth: true, data });
