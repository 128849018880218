import { useEffect, useState } from "react";
import instruction_image from "../../../assets/images/instruction.png";
import ModalSquare from "../../../components/modal-square";
import Overlay from "../../../components/overlay";
import CreateLifeEventComponent from "../components/create-life-event.component";
import SelectCoverImageStyleComponent from "../components/select-cover-image-style.component";
import UploadAndEditImageComponent from "../components/upload-and-edit-image.component";
import { LifeEventEntity } from "../entities/life-event.entity";

export default function CreateLifeEventOverlay({
  isOpen,
  onClose,
  createLifeEvent,
  albumTag,
}: {
  isOpen: boolean;
  onClose: any;
  createLifeEvent: (lifeEvent: LifeEventEntity) => void;
  albumTag?: string;
}) {
  const [page, setPage] = useState(0);
  const [selectedCoverImageStyleId, setSelectedCoverImageStyleId] = useState(0);
  const [newLifeEvent, setNewLifeEvent] = useState<LifeEventEntity>(
    LifeEventEntity.createEmptyLifeEvent(albumTag)
  );
  const submitData = (newImageName: string | undefined) => {
    createLifeEvent({ ...newLifeEvent, imageName: newImageName });
    onClose();
  };

  const updateLifeEvent = (lifeEvent: LifeEventEntity) => {
    setNewLifeEvent(lifeEvent);
    setPage(1);
  };

  useEffect(() => {
    if (isOpen) {
      setNewLifeEvent(LifeEventEntity.createEmptyLifeEvent(albumTag));
      setPage(0);
    }
  }, [setNewLifeEvent, setPage, isOpen, albumTag]);

  return (
    <Overlay open={isOpen} onClose={onClose} iconsTop={[]} iconsBottom={[]}>
      <div className="flex flex-col md:flex-row">
        {page === 0 ? (
          <>
            <ModalSquare className="bg-slate-300">
              <img src={instruction_image} alt="instructions" />
            </ModalSquare>
            <CreateLifeEventComponent
              lifeEvent={newLifeEvent}
              updateLifeEvent={updateLifeEvent}
            />
          </>
        ) : page === 1 ? (
          <SelectCoverImageStyleComponent
            onClickNext={(selectedCoverImageStyle) => {
              setSelectedCoverImageStyleId(selectedCoverImageStyle);
              setPage(2);
            }}
            onClickBack={() => setPage(0)}
          />
        ) : (
          <UploadAndEditImageComponent
            coverImageStyleId={selectedCoverImageStyleId}
            onClickDone={submitData}
            onClickBack={() => setPage(1)}
          />
        )}
      </div>
    </Overlay>
  );
}
