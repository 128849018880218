import FormComponent from "./form-component";
import { FormParams } from "./input";

export interface TextAreaParams extends FormParams {
  maxLength?: number;
}

export default function TextArea(params: TextAreaParams) {
  return (
    <FormComponent
      id={params.id}
      label={params.label}
      className={params.className}
    >
      <div className="mt-1">
        <textarea
          rows={2}
          name={params.id}
          id={params.id}
          value={params.value}
          onChange={params.onChange}
          required={params.required}
          autoComplete={params.autoComplete}
          maxLength={params.maxLength}
          placeholder={params.placeholder}
          className="w-full outline-0 rounded-t-md border-0 border-b-2 border-gray-400 focus:border-indigo-500 focus:ring-0 text-lg lg:text-xl resize-none"
        ></textarea>
      </div>
    </FormComponent>
  );
}
