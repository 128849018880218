export default function ModalSquare({
  children,
  className,
}: {
  children: any;
  className?: string;
}) {
  return (
    <div
      className={`bg-white h-[300px] w-[300px] lg:h-[400px] lg:w-[400px] xl:h-[600px] xl:w-[600px] text-center ${className}`}
    >
      {children}
    </div>
  );
}
