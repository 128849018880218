import { useCallback, useEffect, useState } from "react";
import { useAuthStore } from "../../modules/auth/store/auth.store";
import { LifeEventEntity } from "../../modules/life-event/entities/life-event.entity";
import useWindowDimensions, {
  WIDTH_PREFIX,
} from "../../shared/hooks/get-window-dimensions.hook";
import "./coverflow.component.css";
import { StartPageImage } from "./start-page.component";

export default function CoverflowComponent({
  onChange,
  startAnimation,
  events,
  todayPageIndex,
  activeIndex,
  isAlbum,
}: {
  onChange: any;
  startAnimation: boolean;
  events: LifeEventEntity[];
  activeIndex: number;
  todayPageIndex?: number;
  isAlbum?: boolean;
}) {
  const isInitial = useAuthStore((state) => state.initial);
  const setInitial = useAuthStore((state) => state.setInitial);
  const slides: any[] = [];
  let imageSize = 200;

  const [hoverIndex, setHoverIndex] = useState(-1);
  // const [isDown, setIsDown] = useState(false);
  // const [startX, setStartX] = useState(0);
  const { width } = useWindowDimensions();
  const [visible, setVisible] = useState(false);

  if (width < WIDTH_PREFIX.md) {
    imageSize = 200;
  } else {
    imageSize = 400;
  }

  const getStyle = (index: number) => {
    const translateX = (activeIndex - index) * (imageSize * 0.4);
    const translateZ = -1 * Math.abs(activeIndex - index) * 200;
    const rotate =
      activeIndex - index > 0 ? 40 : activeIndex - index < 0 ? -40 : 0;
    const zIndex = Math.abs(activeIndex - index) * -1;
    const hoverScale =
      hoverIndex === index && hoverIndex !== activeIndex ? 1.1 : 1;

    return {
      display: "flex",
      transition: "transform 1s",
      transform: `translate3d(${translateX}px, 0px, ${translateZ}px) rotateX(0deg) rotateY(${rotate}deg) scale(${hoverScale})`,
      zIndex: zIndex,
    };
  };

  const hover = (index: number) => {
    setHoverIndex(index);
  };

  const hoverOut = () => {
    setHoverIndex(-1);
  };

  const getStyleWrapper = () => {
    const translateX = activeIndex * -imageSize + width / 2 - imageSize / 2;
    return {
      transform: `translate3d(${translateX}px, 0px, 0px)`,
    };
  };

  const preloadImages = useCallback(() => {
    events.forEach((i) => {
      const img = new Image();
      img.src = i.thumbnail ?? i.imageUrl ?? "";
    });
  }, [events]);

  useEffect(() => {
    if (events.length > 0) {
      preloadImages();
    }
  }, [events, preloadImages]);

  const onKeyDown = useCallback(
    (e: any) => {
      if (e.keyCode === 39 && activeIndex < events.length - 1) {
        onChange(activeIndex + 1);
      } else if (e.keyCode === 37 && activeIndex > 0) {
        onChange(activeIndex - 1);
      }
    },
    [onChange, activeIndex, events.length]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);

    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [onKeyDown]);

  // const mouseDown = (e: any) => {
  //   setIsDown(true);
  //   setStartX(e.pageX);
  // };

  // const mouseUp = (e: any) => {
  //   setIsDown(false);
  // };

  // const mouseMove = (e: any) => {
  //   if (isDown) {
  //     e.preventDefault();

  //     const scrollDirection = startX - e.pageX;
  //     if (scrollDirection > 0 && activeIndex < 10) {
  //       setTimeout(() => {
  //         onChange(activeIndex + 1);
  //       }, 300);
  //     } else if (scrollDirection < 0 && activeIndex > 0) {
  //       setTimeout(() => {
  //         onChange(activeIndex - 1);
  //       }, 300);
  //     }
  //   }
  // };

  for (let i = 0; i < events.length; i++) {
    slides.push(
      <div
        className="slide-image"
        style={getStyle(i)}
        key={i}
        data-key={i}
        onClick={() => onChange(i)}
        onMouseOver={() => hover(i)}
        onMouseOut={() => hoverOut()}
      >
        <div
          className="h-[200px] w-[200px] md:h-[400px] md:w-[400px] max-w-none outline outline-[2px] outline-offset-[-15px] outline-white/50 bg-cover grid bg-slate-400"
          style={{
            backgroundImage: `url(${
              events[i].thumbnail ?? events[i].imageUrl
            })`,
          }}
        >
          {i === todayPageIndex && (
            <>
              <button
                className="bg-white rounded-full px-3 py-2 text-xs font-bold m-5 md:m-8 justify-self-end self-end"
                onClick={() => {
                  onChange(todayPageIndex);
                }}
              >
                INSPIRATION <span className="text-xl leading-3">&#8594;</span>
              </button>
            </>
          )}
          {isAlbum && i === 0 && (
            <StartPageImage
              title={events[i].title}
              subtitle={`[${events[i].startDate.substring(0, 4)} - Present]`}
            />
          )}
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (startAnimation && isInitial) {
      setTimeout(() => {
        setVisible(true);
        setInitial();
      }, 6000);
    } else if (!isInitial) {
      setTimeout(() => {
        setVisible(true);
      }, 1000);
    }
  }, [startAnimation, isInitial, setInitial]);

  return (
    <>
      {visible ? (
        <div
          className="coverflow-wrapper overflow-hidden w-full py-10"
          // onMouseDown={(e) => mouseDown(e)}
          // onMouseUp={(e) => mouseUp(e)}
          // onMouseLeave={(e) => mouseUp(e)}
          // onMouseMove={(e) => mouseMove(e)}
        >
          <div className="coverflow flex" style={getStyleWrapper()}>
            {slides}
          </div>
        </div>
      ) : (
        <div className="p-[140px] md:p-[240px]"></div>
      )}
    </>
  );
}
