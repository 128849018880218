import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function ImageChooserComponent({
  label,
  imageList,
  onChange,
  disabled,
}: {
  label: string;
  imageList: any[];
  onChange: (data: any) => void;
  disabled: boolean;
}) {
  const [selectedImage, setSelectedImages] = useState(imageList[0]);
  const [filteredImageList, setFilteredImageList] = useState(imageList);

  const onChangeSelected = (image: any) => {
    setSelectedImages(image);
    onChange(image);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
  };

  const onSearch = (e: any) => {
    e.preventDefault();
    const searchText = e.target.value.toLowerCase();
    if (searchText === "") {
      setFilteredImageList(imageList);
    } else {
      const newImageList = imageList.filter((image) =>
        image.title.toLowerCase().includes(searchText)
      );
      setFilteredImageList(newImageList);
    }
  };

  return (
    <>
      <RadioGroup
        value={selectedImage}
        onChange={onChangeSelected}
        className="mt-2"
      >
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <RadioGroup.Label className="text-sm font-medium text-gray-700">
            {label}
          </RadioGroup.Label>

          <form className="flex relative" onSubmit={onSubmit}>
            <input
              type="text"
              name="search"
              className="rounded-full text-sm"
              onChange={onSearch}
              placeholder="Search"
            />
            <button className="absolute right-0 top-1 pr-2 min-w-[24px]">
              <MagnifyingGlassIcon className="h-7 w-7 stroke-2" />
            </button>
          </form>
        </div>

        <div className="mt-2 flex overflow-x-scroll w-[250px] lg:w-[350px] xl:w-[550px]">
          {filteredImageList.map((image) => (
            <RadioGroup.Option
              key={image.id}
              value={image}
              disabled={disabled}
              className={({ active }) =>
                classNames(
                  active
                    ? "border-indigo-600 ring-2 ring-indigo-600"
                    : "border-gray-300",
                  "relative m-1 flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <span className="flex w-24">
                    <span className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="block text-sm font-medium text-gray-900"
                      >
                        {image.title}
                      </RadioGroup.Label>
                      <RadioGroup.Description>
                        <img src={image.imageUrl} alt="" className="p-2" />
                      </RadioGroup.Description>
                    </span>
                  </span>
                  <span
                    className={classNames(
                      active ? "border" : "border-2",
                      checked && disabled
                        ? "border-slate-600"
                        : checked && !disabled
                        ? "border-indigo-600"
                        : "border-transparent",
                      "pointer-events-none absolute -inset-px rounded-lg"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  );
}
