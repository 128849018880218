import CoverflowSliderComponent from "../../../components/coverflow/coverflow-slider.component";
import CoverflowComponent from "../../../components/coverflow/coverflow.component";
import { useLifeEventStore } from "../../life-event/store/life-event-store";

export default function LifeLineCoverflowComponent({
  onChange,
  startAnimation,
}: {
  onChange: (index: number) => void;
  startAnimation: boolean;
}) {
  const events = useLifeEventStore((state) => state.lifeEvents);
  const todayPageIndex = useLifeEventStore((state) => state.todayPageIndex);
  const activeIndex = useLifeEventStore((state) => state.activeIndex);
  const totalLength = useLifeEventStore((state) => state.totalLength);
  return (
    <>
      <CoverflowComponent
        events={events}
        todayPageIndex={todayPageIndex}
        activeIndex={activeIndex}
        onChange={onChange}
        startAnimation={startAnimation}
      />
      <CoverflowSliderComponent
        startAnimation={startAnimation}
        onChange={onChange}
        activeIndex={activeIndex}
        todayPageIndex={todayPageIndex}
        totalLength={totalLength}
      />
    </>
  );
}
