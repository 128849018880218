import { PlayCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import polaroid_img from "../../../assets/images/polaroid.gif";
import VVHeroButton from "../../../components/buttons/vv-hero-button";
import HeroVideoOverlay from "../overlays/hero-video.overlay";

export default function HeroComponent() {
  const [overlayOpen, setOverlayOpen] = useState(false);

  const onClose = () => {
    setOverlayOpen(false);
  };

  const onClickButton = () => {
    setOverlayOpen(true);
  };

  return (
    <section className="bg-white md:h-[60vh] xl:h-[90vh] flex flex-col md:flex-row items-center xl:container mx-6 xl:mx-auto ">
      <div>
        <h1 className="text-4xl xl:text-7xl mt-10 md:mt-0 text-[#8a8690]">
          The story of you.
        </h1>
        <p className="text-xl xl:text-3xl py-10 text-justify">
          Vidavu means “life view” — your life view. Your amazing past, present
          and future all in one fun place. With Vidavu, you do you. Watch this
          short intro video to get started.
        </p>
        <div className="text-center">
          <VVHeroButton
            onClick={() => {
              onClickButton();
            }}
            label="Watch Video"
            postIcon={
              <PlayCircleIcon className="h-8 xl:h-10 w-8 xl:w-10 text-white"></PlayCircleIcon>
            }
          ></VVHeroButton>
        </div>
      </div>
      <img
        src={polaroid_img}
        alt="hero"
        className="h-[400px] w-[400px] lg:h-[600px] lg:w-[600px]  xl:h-[800px] xl:w-[800px] z-10 mt-10"
      />
      <HeroVideoOverlay
        isOpen={overlayOpen}
        onClose={onClose}
      ></HeroVideoOverlay>
    </section>
  );
}
