import { http } from "../../../shared/services/http.service";
import { ForgotPasswordEmailEntity } from "../entities/forgot-password-email.entity";
import { ForgotPasswordResetEntity } from "../entities/forgot-password-reset.entity";
import { UserLoginEntity } from "../entities/user-login.entity";
import { UserRegisterModel } from "../models/user-register.model";

export const loginApi = (data: UserLoginEntity) =>
  http({ url: "login", method: "POST", auth: false, data });

export const registerApi = (data: UserRegisterModel) =>
  http({ url: "register", method: "POST", auth: false, data });

export const forgetPasswordEmailApi = (data: ForgotPasswordEmailEntity) =>
  http({ url: "request-reset-password", method: "POST", auth: false, data });

export const forgetPasswordResetApi = (data: ForgotPasswordResetEntity) =>
  http({ url: "reset-password", method: "PATCH", auth: false, data });
