import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { AuthStatus, useAuthStore } from "../../modules/auth/store/auth.store";
import { meApi } from "../../modules/user/apis/user.api";
import { UserModel } from "../../modules/user/models/user.model";
import { useUserStore } from "../../modules/user/store/user.store";
import VVButton from "../buttons/vv-button";
import VVLinkButton from "../buttons/vv-link-button";
import LogoWhite from "../logo/logo-white";
import ProfileAvatar from "../profile-avatar";
import ViewsNav from "./views-nav";

export default function Header() {
  const initEffect = useRef<boolean>(false);
  const setUser = useUserStore((state) => state.setUser);
  const user = useUserStore((state) => state.user);
  const status = useAuthStore((state) => state.status);
  let loggedIn: boolean = status === AuthStatus.LOGGED_IN;

  useEffect(() => {
    if (initEffect.current === false) {
      if (loggedIn) {
        meApi().then((res) => {
          const userData = res.data;
          setUser(new UserModel(userData).toEntity());
        });
      }
      return () => {
        initEffect.current = true;
      };
    }
  }, [setUser, loggedIn]);

  return (
    <header className="flex bg-black items-center h-16 md:h-20">
      <div className="ml-5 md:ml-10">
        <LogoWhite />
      </div>
      {loggedIn && (
        <h1 className="text-xs md:text-base text-white self-end pb-1 md:pb-3 font-bold font-[PencilPete]">
          The story of {loggedIn ? user?.firstName : "you"}
        </h1>
      )}
      <div className="grow"></div>

      {loggedIn ? (
        <>
          <nav>
            <ul className="hidden md:flex text-white gap-x-4">
              <ViewsNav />
              <Link to="/user/quizards">
                <li>Quizards</li>
              </Link>
            </ul>
          </nav>
          <div className="lg:px-8"></div>
          <div className="hidden md:flex pr-8 py-2">
            <ProfileAvatar imageUrl={user?.profilePicture} />
          </div>
        </>
      ) : (
        <>
          <VVLinkButton
            label="Login"
            url="/login"
            className="text-white mx-1 md:mx-2"
          ></VVLinkButton>
          <VVLinkButton
            label="Try for free"
            url="/register"
            className="bg-white md:mx-2"
          ></VVLinkButton>
          <VVButton onClick={() => {}} className="text-white md:mx-2">
            <div className="flex gap-1">
              US
              <GlobeAltIcon className="text-white h-5 md:h-6 w-5 md:w-6" />
            </div>
          </VVButton>
        </>
      )}
    </header>
  );
}
