import { Navigate } from "react-router-dom";
import { AuthStatus, useAuthStore } from "../modules/auth/store/auth.store";

export default function ProtectedRoute({ children }: { children: any }) {
  const status = useAuthStore((state) => state.status);
  if (status !== AuthStatus.LOGGED_IN) {
    return <Navigate to="/login" replace />;
  }

  return children;
}
