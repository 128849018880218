import FormComponent from "./form-component";
import Input, { FormParams } from "./input";

export default function NumberInput(params: FormParams) {
  return (
    <FormComponent
      id={params.id}
      label={params.label}
      className={params.className}
    >
      <Input type="number" {...params} />
    </FormComponent>
  );
}
