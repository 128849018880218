import { Link } from "react-router-dom";
import ModalSquare from "../../../components/modal-square";
import { formatDate, showOnlyYear } from "../../../shared/utils/date.utils";
import { TODAY_PAGE_BANNERS } from "../constants/life-event.constants";
import {
  LifeEventEntity,
  LifeEventTypeEnum,
} from "../entities/life-event.entity";
import AdditionalMediaComponent from "./additional-media.component";

export default function LifeEventComponent({
  lifeEvent,
  activeIndex,
  todayIndex = -1,
}: {
  lifeEvent: LifeEventEntity;
  activeIndex: number;
  todayIndex?: number;
}) {
  return (
    <>
      {activeIndex === todayIndex ? (
        <ModalSquare>
          <Link to="http://quizard1.vidavu.rocks/" target="_blank">
            <img src={TODAY_PAGE_BANNERS[0]} alt="banner 1" />
          </Link>
          <Link to="http://quizard2.vidavu.rocks/" target="_blank">
            <img src={TODAY_PAGE_BANNERS[1]} alt="banner 2" />
          </Link>
          <Link to="http://quizard3.vidavu.rocks/" target="_blank">
            <img src={TODAY_PAGE_BANNERS[2]} alt="banner 3" />
          </Link>
        </ModalSquare>
      ) : (
        <ModalSquare className="overflow-scroll px-6">
          <h2 className="inline-flex bg-[#0dc3b8] text-white text-lg font-bold px-3 py-1">
            {lifeEvent.type === LifeEventTypeEnum.future ||
            lifeEvent.type === LifeEventTypeEnum.cover
              ? showOnlyYear(lifeEvent.startDate)
              : formatDate(lifeEvent.startDate)}
          </h2>
          <div className="py-2"></div>
          <div className="flex">
            <img
              className="h-[50px] w-[50px] lg:h-[80px] lg:w-[80px] max-w-none self-center"
              src={"https://i.stack.imgur.com/WtGFM.png"}
              alt="Life category icon"
            />

            <h1 className="font-bold  lg:text-2xl text-left pl-4 self-center font-[Oswald]">
              {lifeEvent.title}
            </h1>
          </div>
          <div className="py-2"></div>
          <p className="text-justify font-[PencilPete] lg:text-lg">
            {lifeEvent.description}
          </p>
          <div className="py-4">
            <AdditionalMediaComponent lifeEvent={lifeEvent} />
          </div>
        </ModalSquare>
      )}
    </>
  );
}
