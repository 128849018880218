import { UserEntity } from "../entities/user.entity";

interface IUpdateUserModel {
  firstName: string;
  lastName: string;
  birthDay: string;
  profilePhoto: string;
}

export class UpdateUserModel {
  firstName: string;
  lastName: string;
  birthDay: string;
  profilePhoto: string;

  constructor(data: IUpdateUserModel) {
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.birthDay = data.birthDay;
    this.profilePhoto = data.profilePhoto;
  }

  static fromEntity(entity: UserEntity) {
    return new UpdateUserModel({
      firstName: entity.firstName,
      lastName: entity.lastName,
      birthDay: entity.dob,
      profilePhoto: entity.profilePictureName ?? "",
    });
  }
}
