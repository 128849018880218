export interface AdditionalMediaEntity {
  id?: number;
  pageId: number;
  mediaType: MediaType;
  mediaUrl: string;
  date: string;
  description: string;
  fileName: string;
}

export enum MediaType {
  VIDEO = "VIDEO",
  IMAGE = "IMAGE",
}
