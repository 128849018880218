import { FRAME_IMAGE } from "./life-event.constants";

export const FRAMES = [
  {
    id: 0,
    title: "General Circle",
    imageUrl: FRAME_IMAGE.GENERAL_CIRCLE,
  },
  {
    id: 1,
    title: "General Construction",
    imageUrl: FRAME_IMAGE.GENERAL_CONSTRUCTION,
  },
  {
    id: 2,
    title: "Life",
    imageUrl: FRAME_IMAGE.GENERAL_LIFE,
  },
  {
    id: 3,
    title: "Graduation",
    imageUrl: FRAME_IMAGE.GRADUATION,
  },
  {
    id: 4,
    title: "Halloween",
    imageUrl: FRAME_IMAGE.HALLOWEEN,
  },
  {
    id: 5,
    title: "Love",
    imageUrl: FRAME_IMAGE.LOVE,
  },
  {
    id: 6,
    title: "Wedding",
    imageUrl: FRAME_IMAGE.WEDDING,
  },
];
