import {
  LifeEventEntity,
  LifeEventTypeEnum,
} from "../entities/life-event.entity";

interface ILifeEventModel {
  id?: number;
  title: string;
  description: string;
  coverImage?: string;
  coverImageUrl?: string;
  thumbnail?: string;
  pageStartDate: string;
  pageEndDate?: string;
  type?: LifeEventTypeEnum;
  category: string;
  tags?: string;
  isDateRangeVerified: number;
  hasAdditionalMedia?: number;
  quizardTag?: string;
}

export class LifeEventModel {
  id?: number;
  title: string;
  description: string;
  pageStartDate: string;
  pageEndDate?: string;
  coverImage?: string;
  coverImageUrl?: string;
  thumbnail?: string;
  type?: LifeEventTypeEnum;
  category: string;
  tags?: string;
  isDateRangeVerified: number;
  hasAdditionalMedia?: number;
  quizardTag?: string;

  constructor(data: ILifeEventModel) {
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.coverImage = data.coverImage;
    this.coverImageUrl = data.coverImageUrl;
    this.thumbnail = data.thumbnail;
    this.pageStartDate = data.pageStartDate;
    this.pageEndDate = data.pageEndDate;
    this.type = data.type;
    this.category = data.category;
    this.tags = data.tags;
    this.isDateRangeVerified = data.isDateRangeVerified;
    this.hasAdditionalMedia = data.hasAdditionalMedia;
    this.quizardTag = data.quizardTag;
  }

  static fromEntity(entity: LifeEventEntity) {
    return new LifeEventModel({
      id: entity.id < 0 ? undefined : entity.id,
      title: entity.title,
      description: entity.description,
      coverImage: entity.imageName,
      pageStartDate: entity.startDate,
      pageEndDate: entity.endDate === "" ? entity.startDate : entity.endDate,
      category: entity.category,
      tags: entity.customTags,
      isDateRangeVerified: entity.isDateRangeVerified ? 1 : 0,
      quizardTag: entity.albumTag,
    });
  }

  toEntity(): LifeEventEntity {
    const coverImageUrl =
      this.coverImageUrl ?? "https://swiperjs.com/demos/images/nature-2.jpg";
    const selectedThumbnail =
      this.thumbnail && this.thumbnail !== "" ? this.thumbnail : coverImageUrl;
    return new LifeEventEntity({
      id: this.id ?? 0,
      title: this.title,
      description: this.description.substring(0, 100),
      startDate: this.pageStartDate.substring(0, 10),
      endDate: this.pageEndDate ? this.pageEndDate.substring(0, 10) : "",
      imageName: this.coverImage,
      imageUrl: coverImageUrl,
      thumbnail: selectedThumbnail,
      type: this.type,
      category: this.category,
      customTags: this.tags,
      isDateRangeVerified: this.isDateRangeVerified === 1,
      isAdditionalMedia: this.hasAdditionalMedia === 1,
      albumTag: this.quizardTag,
    });
  }
}
