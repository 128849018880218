import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFocus } from "../../../shared/hooks/use-focus.hook";
import { FormFunctions, useForm } from "../../../shared/hooks/use-form.hook";
import { AuthStatus, useAuthStore } from "../../auth/store/auth.store";
import { FlashboardParamsEntity } from "../entities/flashboard-params.entity";
import "./flashboard.page.css";

export default function FlashboardPage() {
  const [inputRef1, setInputFocus1] = useFocus();
  const [inputRef2, setInputFocus2] = useFocus();
  const [inputRef3, setInputFocus3] = useFocus();
  const [inputRef4, setInputFocus4] = useFocus();
  const [inputRefSubmit, setInputFocusSubmit] = useFocus();

  const navigate = useNavigate();
  const submitPin = useAuthStore((state) => state.submitPin);
  const authState = useAuthStore((state) => state.status);

  useEffect(() => {
    if (
      authState === AuthStatus.LOGGED_IN ||
      authState === AuthStatus.LOGGED_OUT
    ) {
      navigate("/");
    }
  }, [authState, navigate]);

  const handleSubmit = () => {
    submitPin(formFunc.values);
  };

  const setFocus = (id: number) => {
    switch (id) {
      case 1:
        setInputFocus1();
        return;
      case 2:
        setInputFocus2();
        return;
      case 3:
        setInputFocus3();
        return;
      case 4:
        setInputFocus4();
        return;
      case 5:
        setInputFocusSubmit();
        return;
    }
  };

  const onBackButton = (event: any, id: number) => {
    if (event.keyCode === 8) {
      setFocus(id - 1);
    }
  };

  const onInputPin = (event: any, id: number) => {
    if (event.target.value.length > 1)
      event.target.value = event.target.value % 10;
    formFunc.onChange(event);
    if (event.target.value !== "") {
      setFocus(id + 1);
    }
  };

  const formFunc: FormFunctions<FlashboardParamsEntity> = useForm(
    handleSubmit,
    {
      input1: "",
      input2: "",
      input3: "",
      input4: "",
    }
  );

  return (
    <div className="overflow-hidden">
      <div className="bg-slate-700 w-full h-screen relative z-0">
        <video autoPlay loop muted className="min-w-full min-h-full max-w-none">
          <source
            src="https://vidavu-assests-stage.s3.amazonaws.com/assets/intro_video.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 flex justify-center items-center z-10">
          <div
            className="col-12 col-md-8 col-lg-8"
            style={{ position: "fixed", top: "10%" }}
          >
            <img
              src="https://vidavu-assests-stage.s3.amazonaws.com/assets/vidavu_gif.gif"
              style={{ width: "inherit" }}
              alt="motto gif"
            />
          </div>

          <div className="bg-img flex flex-col items-center w-[350px] md:w-[400px] h-[250px] text-white">
            <img
              className="-mt-[50px]"
              style={{ width: "25%" }}
              src="https://vidavu-assests-stage.s3.amazonaws.com/assets/logo_image.png"
              alt="Logo"
            />
            <h1 className="tracking-widest text-2xl">PRE-LAUNCH</h1>
            <div className="flex mt-5 items-center">
              <input
                name="input1"
                id="input1"
                className="w-12 h-14 text-2xl bg-transparent border-white border-3 mx-2 text-center"
                type="password"
                onChange={(event) => onInputPin(event, 1)}
                ref={inputRef1}
                autoFocus
                value={formFunc.values.input1}
              />
              <input
                id="input2"
                name="input2"
                className="w-12 h-14 text-2xl bg-transparent border-white border-3 mx-2 text-center"
                type="password"
                onChange={(event) => onInputPin(event, 2)}
                onKeyUp={(event) => onBackButton(event, 2)}
                ref={inputRef2}
              />
              <input
                id="input3"
                name="input3"
                className="w-12 h-14 text-2xl bg-transparent border-white border-3 mx-2 text-center"
                type="password"
                onChange={(event) => onInputPin(event, 3)}
                onKeyUp={(event) => onBackButton(event, 3)}
                ref={inputRef3}
              />
              <input
                name="input4"
                id="input4"
                className="w-12 h-14 text-2xl bg-transparent border-white border-3 mx-2 text-center"
                type="password"
                onChange={(event) => onInputPin(event, 4)}
                onKeyUp={(event) => onBackButton(event, 4)}
                ref={inputRef4}
              />
              <input
                type="image"
                id="submit-button"
                className="w-12 h-12 mx-2"
                src="https://inf-shared-assets-s3.s3.amazonaws.com/platform-login-screen/login-button-white.png"
                alt="Submit"
                width="100%"
                height="100%"
                onClick={handleSubmit}
                ref={inputRefSubmit}
              />
            </div>
            <p className="text-center mt-5 text-sm">
              If you have a PIN, you are one of the chosen few
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
