import {
  MagnifyingGlassIcon,
  PlayIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import startSound from "../../../assets/sounds/start_sound.mp3";
import VVButton from "../../../components/buttons/vv-button";
import { formatDate, showOnlyYear } from "../../../shared/utils/date.utils";
import { useAuthStore } from "../../auth/store/auth.store";
import { LifeEventTypeEnum } from "../../life-event/entities/life-event.entity";
import CreateLifeEventOverlay from "../../life-event/overlays/create-life-event.overlay";
import LifeEventOverlay from "../../life-event/overlays/life-event.overlay";
import { MY_ALBUM_TAG } from "../apis/album.api";
import AlbumCoverflowComponent from "../components/album-coverflow.component";
import { useAlbumStore } from "../store/album.store";

export default function AlbumPage() {
  const params = useParams();
  const initEffect = useRef<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const activeIndex = useAlbumStore((state) => state.activeIndex);
  const setLifeEvents = useAlbumStore((state) => state.setLifeEvents);
  const setActiveIndex = useAlbumStore((state) => state.setActiveIndex);
  const selectedLifeEvent = useAlbumStore((state) => state.selectedLifeEvent);
  const [startAnimation, setStartAnimation] = useState(false);
  const isInitial = useAuthStore((state) => state.initial);
  const createLifeEvent = useAlbumStore((state) => state.createLifeEvent);
  const editLifeEvent = useAlbumStore((state) => state.editLifeEvent);
  const deleteLifeEvent = useAlbumStore((state) => state.deleteLifeEvent);
  const [albumTag, setAlbumTag] = useState(MY_ALBUM_TAG);

  const startAudio = new Audio(startSound);

  useEffect(() => {
    setAlbumTag(params.albumTag ?? MY_ALBUM_TAG);
    setLifeEvents(params.albumTag ?? MY_ALBUM_TAG);
  }, [setLifeEvents, params.albumTag]);

  // TODO: Remove after happiness overlay implementation
  useEffect(() => {
    if (initEffect.current === false) {
      if (isInitial) {
        onClose();
      }
      return () => {
        initEffect.current = true;
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (index: number) => {
    if (index === activeIndex) {
      setIsModalOpen(true);
    } else {
      setActiveIndex(MY_ALBUM_TAG, index);
    }
  };

  const onClose = () => {
    setStartAnimation(true);
    startAudio.play();
  };

  return (
    <>
      <div className="p-4 lg:p-10"></div>
      <div className="flex flex-col sm:flex-row justify-center">
        <h1 className="m-1 md:m-2 border-2 px-2 border-black text-xs md:text-sm self-center font-semibold">
          {selectedLifeEvent.type === LifeEventTypeEnum.cover
            ? showOnlyYear(selectedLifeEvent?.startDate)
            : formatDate(selectedLifeEvent?.startDate)}
        </h1>
        <h1 className="m-1 md:m-2 text-xl md:text-4xl text-[#778282] font-[Oswald] text-center">
          {selectedLifeEvent?.title}
        </h1>
      </div>
      <p className="my-1 mx-3 md:my-2 md:mx-auto text-center text-sm md:text-2xl font-[PencilPete] max-w-xl">
        {selectedLifeEvent?.description}
      </p>

      <AlbumCoverflowComponent
        onChange={onChange}
        startAnimation={startAnimation}
      />

      <div className="flex mx-5 md:mx-40">
        <VVButton className="bg-[#ededed]">
          <div className="flex gap-1">
            Slide Show View
            <PlayIcon className="h-6 w-6" />
          </div>
        </VVButton>
        <div className="grow"></div>

        <form className="flex relative">
          <input type="text" name="search" className="rounded-full text-sm" />
          <button className="absolute right-0 top-1 pr-2 min-w-[24px]">
            <MagnifyingGlassIcon className="h-7 w-7 stroke-2" />
          </button>
        </form>
        <div className="grow"></div>

        <VVButton
          className="bg-[#ededed]"
          onClick={() => setIsCreateModalOpen(true)}
        >
          <div className="flex gap-1">
            Add New Page
            <PlusCircleIcon className="h-6 w-6" />
          </div>
        </VVButton>
        <LifeEventOverlay
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          lifeEvent={selectedLifeEvent}
          activeIndex={activeIndex}
          editLifeEvent={(lifeEvent) => editLifeEvent(albumTag, lifeEvent)}
          deleteLifeEvent={(lifeEventId) =>
            deleteLifeEvent(albumTag, lifeEventId)
          }
        />
        <CreateLifeEventOverlay
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          createLifeEvent={(lifeEvent) => createLifeEvent(albumTag, lifeEvent)}
          albumTag={albumTag}
        />
      </div>
    </>
  );
}
