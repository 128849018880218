import { http } from "../../../shared/services/http.service";
import { LifeEventModel } from "../../life-event/models/life-event.model";

export const MY_ALBUM_TAG = "my-album";

export const getAlbumPagesApi = async (page: number, albumTag: string) =>
  albumTag === MY_ALBUM_TAG
    ? http({
        url: "user-pages",
        method: "GET",
        auth: true,
        params: {
          page,
        },
      })
    : http({
        url: "quizard/pages",
        method: "GET",
        auth: true,
        params: {
          page,
          quizardTag: albumTag,
        },
      });

export const createAlbumPageApi = async (
  data: LifeEventModel,
  albumTag: string
) =>
  albumTag === MY_ALBUM_TAG
    ? http({ url: "user-pages", method: "POST", auth: true, data })
    : http({ url: "quizard", method: "POST", auth: true, data });

export const editAlbumPageApi = async (data: LifeEventModel) =>
  http({ url: "user-pages", method: "PUT", auth: true, data });

export const deleteAlbumPageApi = async (id: number) =>
  http({ url: `user-pages/${id}`, method: "DELETE", auth: true });
