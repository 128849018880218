import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

export default function Logo() {
  return (
    <Link to="/">
      <img src={logo} className="w-24 md:w-36 h-auto mx-auto" alt="logo" />
    </Link>
  );
}
