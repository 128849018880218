import ModalSquare from "../../../components/modal-square";

import { RadioGroup } from "@headlessui/react";
import { useState } from "react";
import VVButton from "../../../components/buttons/vv-button";
import {
  COVER_IMAGE_STYLES,
  INSTRUCTIONS_IMAGE,
} from "../constants/life-event.constants";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectCoverImageStyleComponent({
  onClickNext,
  onClickBack,
}: {
  onClickNext: (selectedCoverImageStyle: number) => void;
  onClickBack: () => void;
}) {
  const [selectedCoverImageStyle, setSelectedCoverImageStyle] = useState(
    COVER_IMAGE_STYLES[0]
  );

  return (
    <>
      <ModalSquare>
        <div className="">
          <img
            src={
              selectedCoverImageStyle.id === 0
                ? INSTRUCTIONS_IMAGE.YOUR_PHOTO
                : selectedCoverImageStyle.id === 1
                ? INSTRUCTIONS_IMAGE.DIGITAL_IMAGE
                : selectedCoverImageStyle.id === 2
                ? INSTRUCTIONS_IMAGE.FRAMES
                : INSTRUCTIONS_IMAGE.COVER_ART
            }
            alt="instructions"
          />
        </div>
      </ModalSquare>
      <ModalSquare className="overflow-scroll">
        <div className="mt-4">
          <h1 className="font-bold">STEP 2</h1>
          <h2>Select a Cover Image Style</h2>

          <RadioGroup
            value={selectedCoverImageStyle}
            onChange={setSelectedCoverImageStyle}
          >
            <div className="mt-4 mx-20 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
              {COVER_IMAGE_STYLES.map((coverImageStyle) => (
                <RadioGroup.Option
                  key={coverImageStyle.id}
                  value={coverImageStyle}
                  className={({ active }) =>
                    classNames(
                      active
                        ? "border-indigo-600 ring-2 ring-indigo-600"
                        : "border-gray-300",
                      "relative flex cursor-pointer rounded-lg border bg-white px-8 py-4 shadow-sm focus:outline-none"
                    )
                  }
                >
                  {({ checked, active }) => (
                    <>
                      <span className="flex flex-1">
                        <span className="flex flex-col">
                          <RadioGroup.Description>
                            <img
                              src={coverImageStyle.image}
                              alt=""
                              className="p-2"
                            />
                          </RadioGroup.Description>
                          <RadioGroup.Label
                            as="span"
                            className="block text-sm font-medium text-gray-900"
                          >
                            {coverImageStyle.title}
                          </RadioGroup.Label>
                        </span>
                      </span>
                      <span
                        className={classNames(
                          active ? "border" : "border-2",
                          checked ? "border-indigo-600" : "border-transparent",
                          "pointer-events-none absolute -inset-px rounded-lg"
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
          <div className="flex justify-center py-5">
            <VVButton
              type="button"
              onClick={onClickBack}
              className="bg-slate-300 self-center"
            >
              Go Back
            </VVButton>
            <div className="p-2"></div>
            <VVButton
              className="bg-indigo-600 self-center text-white"
              onClick={() => onClickNext(selectedCoverImageStyle.id)}
            >
              Next Step
            </VVButton>
          </div>
        </div>
      </ModalSquare>
    </>
  );
}
