import { create } from "zustand";
import {
  LifeEventEntity,
  LifeEventTypeEnum,
} from "../../life-event/entities/life-event.entity";
import { LifeEventModel } from "../../life-event/models/life-event.model";
import {
  createAlbumPageApi,
  deleteAlbumPageApi,
  editAlbumPageApi,
  getAlbumPagesApi,
} from "../apis/album.api";

interface AlbumState {
  lifeEvents: LifeEventEntity[];
  activeIndex: number;
  selectedLifeEvent: LifeEventEntity;
  totalLength: number;
  perPage: number;
  setActiveIndex: (albumTag: string, activeIndex: number) => void;
  setLifeEvents: (albumTag: string, activeIndex?: number) => void;
  editLifeEvent: (albumTag: string, lifeEvent: LifeEventEntity) => void;
  createLifeEvent: (albumTag: string, lifeEvent: LifeEventEntity) => void;
  deleteLifeEvent: (albumTag: string, id: number) => void;
}

export const useAlbumStore = create<AlbumState>((set, get) => ({
  lifeEvents: [],
  activeIndex: 0,
  selectedLifeEvent: LifeEventEntity.createLoadingLifeEvent(0),
  totalLength: 0,
  perPage: 0,
  setActiveIndex: async (albumTag: string, activeIndex: number) => {
    const perPage = get().perPage;

    // Dynamically added today page adds one extra page
    const pageNumber = Math.ceil((activeIndex + 1) / perPage);

    const lifeEvents = get().lifeEvents;
    if (lifeEvents[activeIndex].title === "Loading...") {
      const response = await getAlbumPagesApi(pageNumber, albumTag);
      const data = response.data.data;
      const total = response.data.meta.total + 1; // +1 for album cover
      const lifeEventPage: LifeEventEntity[] = data.map((item: any) =>
        new LifeEventModel(item).toEntity()
      );
      let totalCoverflow: LifeEventEntity[] = [];

      // Generate coverflow for each ranges
      for (let i = 0; i < total; i++) {
        if (i <= (pageNumber - 1) * perPage) {
          totalCoverflow.push(lifeEvents[i]);
        } else if (i <= pageNumber * perPage) {
          totalCoverflow.push(lifeEventPage[i - (pageNumber - 1) * perPage]);
        } else {
          totalCoverflow.push(lifeEvents[i]);
        }
      }
      set((state) => ({
        lifeEvents: totalCoverflow,
      }));
    }
    set((state) => ({
      activeIndex,
      selectedLifeEvent: state.lifeEvents[activeIndex],
    }));
  },
  setLifeEvents: async (albumTag: string, activeIndex?: number) => {
    let lifeEvents: LifeEventEntity[] = [];
    let totalLength: number = 0;
    let perPage: number = 0;

    try {
      const response = await getAlbumPagesApi(1, albumTag);
      totalLength = response.data.meta.total + 1; // +1 for album cover
      perPage = response.data.meta.perPage;
      const data = response.data.data;
      const cover = response.data.quizardInfo;

      for (let i = 0; i < totalLength; i++) {
        if (i === 0) {
          lifeEvents.push(
            new LifeEventEntity({
              id: 0,
              title: cover.headLine ?? "Quizard Title",
              description: "",
              startDate: cover.userPageStartYear?.toString() ?? "2023",
              endDate: cover.userPageEndYear?.toString(),
              category: "",
              isDateRangeVerified: false,
              isAdditionalMedia: false,
              imageUrl: cover.imageUrl,
              type: LifeEventTypeEnum.cover,
            })
          );
        } else if (i <= data.length) {
          lifeEvents.push(new LifeEventModel(data[i - 1]).toEntity());
        } else {
          lifeEvents.push(LifeEventEntity.createLoadingLifeEvent(i - 1));
        }
      }
    } catch (e) {
      console.error(e);
    }

    set({
      lifeEvents,
      totalLength,
      perPage,
      activeIndex: activeIndex ?? 0,
      selectedLifeEvent: lifeEvents[activeIndex ?? 0],
    });

    get().setActiveIndex(albumTag, activeIndex ?? 0);
  },
  editLifeEvent: async (albumTag: string, lifeEvent: LifeEventEntity) => {
    try {
      const response = await editAlbumPageApi(
        LifeEventModel.fromEntity(lifeEvent)
      );
      get().setLifeEvents(albumTag, response.data.pageIndex - 1);
    } catch (e) {
      console.error(e);
    }
  },

  createLifeEvent: async (albumTag: string, lifeEvent: LifeEventEntity) => {
    try {
      const response = await createAlbumPageApi(
        LifeEventModel.fromEntity(lifeEvent),
        albumTag
      );
      get().setLifeEvents(albumTag, response.data.pageIndex - 1);
    } catch (e) {
      console.error(e);
    }
  },
  deleteLifeEvent: async (albumTag: string, id: number) => {
    try {
      await deleteAlbumPageApi(id);

      get().setLifeEvents(albumTag);
    } catch (e) {
      console.error(e);
    }
  },
}));
