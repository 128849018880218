import ReactGA from "react-ga4";
import { Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import NotFoundPage from "./components/404";
import Layout from "./components/layout";
import ProtectedRoute from "./components/protected-route";
import ProtectedSite from "./components/protected-site";
import AlbumPage from "./modules/album/pages/album.page";
import ForgotPasswordEmailPage from "./modules/auth/pages/forgot-password-email.page";
import ForgotPasswordResetPage from "./modules/auth/pages/forgot-password-reset.page";
import FlashboardPage from "./modules/flashboard/pages/flashboard.page";
import HomePage from "./modules/home/pages/home.page";
import LifeLinePage from "./modules/life-line/pages/life-line.page";
import { QuizardsPage } from "./modules/quizards/pages/quizards.page";
import ProfilePage from "./modules/user/pages/profile.page";

function App() {
  const TRACKING_ID = "G-FDM94XDHH8";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({
    hitType: "pageview",
    page: "/user",
    title: "lifeLine",
  });

  return (
    <Routes>
      <Route path="/flashboard" element={<FlashboardPage />} />

      {["/", "/login", "/register", "/register-complete"].map((path, index) => {
        return (
          <Route
            path={path}
            element={
              <ProtectedSite>
                <HomePage />
              </ProtectedSite>
            }
            key={index}
          />
        );
      })}
      <Route
        path="/forgot-password"
        element={
          <ProtectedSite>
            <ForgotPasswordEmailPage />
          </ProtectedSite>
        }
      />
      <Route
        path="/forgot-password-reset"
        element={
          <ProtectedSite>
            <ForgotPasswordResetPage />
          </ProtectedSite>
        }
      />
      <Route
        path="/user"
        element={
          <ProtectedSite>
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          </ProtectedSite>
        }
      >
        <Route path="" element={<LifeLinePage />} />
        <Route path="albums" element={<Outlet />}>
          <Route path=":albumTag" element={<AlbumPage />} />
        </Route>
        <Route path="profile" element={<ProfilePage />} />
        <Route path="quizards" element={<QuizardsPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />}></Route>
    </Routes>
  );
}

export default App;
