import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import VVButton from "../../../components/buttons/vv-button";
import ColorPickerInput from "../../../components/form/color-picker-input";
import Select from "../../../components/form/select";
import ModalSquare from "../../../components/modal-square";
import useWindowDimensions, {
  WIDTH_PREFIX,
} from "../../../shared/hooks/get-window-dimensions.hook";
import {
  FileUploadCategory,
  uploadFile,
} from "../../../shared/services/file-upload.service";
import { COVER_ARTS } from "../constants/image-cover-art.constants";
import { IMAGE_FILTERS } from "../constants/image-filter.constants";
import { FRAMES } from "../constants/image-frame-constants";
import ImageChooserComponent from "./image-chooser.component";
import ImageEditSliderComponent from "./image-edit-slider.component";

export interface ImageEditConfig {
  brightness: number;
  contrast: number;
  grayscale: number;
  hue: number;
  saturation: number;
}

export default function UploadAndEditImageComponent({
  coverImageStyleId,
  imageUrl,
  onClickDone,
  onClickBack,
}: {
  coverImageStyleId: number;
  imageUrl?: string;
  onClickDone: (newImageURL: string | undefined) => void;
  onClickBack: () => void;
}) {
  const initEffect = useRef<boolean>(false);
  const [preview, setPreview] = useState(imageUrl);
  const [imgFile, setImgFile] = useState<string | File | null>(null);
  const [editor, setEditor] = useState<AvatarEditor | null>(null);
  const [scale, setScale] = useState(1);
  const [backgroundColor, setBackgroundColor] = useState("#0eafa9");

  const [frameImage, setFrameImage] = useState(FRAMES[0].imageUrl);

  const [brightness, setBrightness] = useState(100);
  const [contrast, setContrast] = useState(100);
  const [grayscale, setGrayscale] = useState(0);
  const [hue, setHue] = useState(0);
  const [saturation, setSaturation] = useState(100);

  let canvasSize = 600;

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (initEffect.current === false) {
      if (coverImageStyleId === 3) {
        createImageFromCoverArt(COVER_ARTS[0].imageUrl);
      }
    }
    return () => {
      initEffect.current = true;
    };
  }, [coverImageStyleId]);

  const setImageState = (config: ImageEditConfig) => {
    setBrightness(config.brightness);
    setContrast(config.contrast);
    setGrayscale(config.grayscale);
    setHue(config.hue);
    setSaturation(config.saturation);
  };

  const onChangeColor = (e: ChangeEvent<HTMLInputElement>) => {
    setBackgroundColor(e.target.value);
  };

  if (width < WIDTH_PREFIX.lg) {
    canvasSize = 300;
  } else if (width < WIDTH_PREFIX.xl) {
    canvasSize = 400;
  }

  const onChange = (e: any) => {
    // setInitialState();
    createImage(e.target.files[0]);
  };

  const createImage = (file: any) => {
    if (file) {
      createPreview(file);
      setImgFile(file);
    }
  };

  const createImageFromCoverArt = (url: string) => {
    if (url) {
      setPreview(url);
      setImgFile(url);
    }
  };

  const createPreview = (file: File) => {
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
  };

  const onClickReset = () => {
    setImgFile(null);
    setPreview(imageUrl);
  };

  const getFilter = () => {
    return `brightness(${brightness}%) contrast(${contrast}%) grayscale(${grayscale}%) saturate(${saturation}%) hue-rotate(${hue}deg)`;
  };

  const onDone = async () => {
    if (editor) {
      let canvas = editor.getImageScaledToCanvas();
      let ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.filter = getFilter();
        ctx.drawImage(canvas, 0, 0);

        if (coverImageStyleId === 2 && frameImage) {
          let frame = new Image();
          frame.src = frameImage;
          frame.onload = () => {
            ctx?.drawImage(frame, 0, 0, canvas.width, canvas.height);
            if (typeof imgFile !== "string") {
              canvas.toBlob(async (blob: any) => {
                let file = new File([blob], imgFile!.name, {
                  type: imgFile!.type,
                });
                await uploadImage(file);
              }, imgFile!.type);
            }
          };
        } else if (typeof imgFile === "string") {
          canvas.toBlob(async (blob: any) => {
            let file = new File([blob], "covert-art.jpg", {
              type: "image/jpeg",
            });
            await uploadImage(file);
          }, "image/jpeg");
        } else {
          canvas.toBlob(async (blob: any) => {
            let file = new File([blob], imgFile!.name, { type: imgFile!.type });
            await uploadImage(file);
          }, imgFile!.type);
        }
      }
    }
  };

  const uploadImage = async (file: File) => {
    createPreview(file);

    setImgFile(null);
    let imageName = await uploadFile(file, FileUploadCategory.PAGE);
    onClickDone(imageName);
  };

  const setEditorRef = (editor: AvatarEditor) => {
    setEditor(editor);
  };

  const getYourImage = (coverImageStyleId: number) => {
    return (
      <>
        <h2>
          Upload & Edit{" "}
          {coverImageStyleId === 0 ? "Your Photo" : "a Digital Image"}
        </h2>

        <div className="flex flex-col mt-10 items-center">
          <ColorPickerInput
            id="backgroundColor"
            label="Select Background Color"
            value={backgroundColor}
            className="w-48 p-2"
            onChange={onChangeColor}
            disabled={!imgFile}
          />
          <ImageEditSliderComponent
            id="zoom"
            title="Zoom"
            initialState={scale}
            scale={0.1}
            min={0.5}
            max={2}
            onChange={(val: number) => setScale(val)}
            disabled={!imgFile}
          />
          <ImageChooserComponent
            label="Select Cover Art"
            imageList={IMAGE_FILTERS}
            disabled={!imgFile}
            onChange={(image) => setImageState(image.config)}
          />
        </div>
      </>
    );
  };

  const getPhotoFrame = () => {
    return (
      <>
        <h2>Upload Your Photo and Select a Frame</h2>

        <div className="flex flex-col mt-10 items-center">
          <ColorPickerInput
            id="backgroundColor"
            label="Select Background Color"
            value={backgroundColor}
            className="w-48 p-2"
            onChange={onChangeColor}
            disabled={!imgFile}
          />
          <ImageEditSliderComponent
            id="zoom"
            title="Zoom"
            initialState={scale}
            scale={0.1}
            min={0.5}
            max={2}
            onChange={(val: number) => setScale(val)}
            disabled={!imgFile}
          />
          <ImageChooserComponent
            label="Select a Photo Frame"
            imageList={FRAMES}
            disabled={!imgFile}
            onChange={(image) => setFrameImage(image.imageUrl)}
          />
        </div>
      </>
    );
  };

  const getVidavuCover = () => {
    return (
      <>
        <h2>Select a Vidavu Cover Image</h2>

        <div className="flex flex-col mt-10 items-center">
          <Select
            id="theme"
            label="Select a Theme"
            className="w-full"
            options={[
              { id: "theme 1", value: "Theme 1" },
              { id: "theme 2", value: "Theme 2" },
              { id: "theme 3", value: "Theme 3" },
              { id: "theme 4", value: "Theme 4" },
            ]}
          />
          <ImageChooserComponent
            label="Select a Cover Photo"
            imageList={COVER_ARTS}
            disabled={!imgFile}
            onChange={(image) => createImageFromCoverArt(image.imageUrl)}
          />
        </div>
      </>
    );
  };

  return (
    <>
      {imgFile ? (
        <ModalSquare>
          <div className="flex w-[600px] h-[600px]">
            <AvatarEditor
              ref={setEditorRef}
              image={imgFile!}
              width={canvasSize}
              height={canvasSize}
              backgroundColor={backgroundColor}
              border={0}
              scale={scale}
              rotate={0}
              style={{
                filter: getFilter(),
              }}
              disableCanvasRotation={false}
              disableBoundaryChecks={false}
            />
            {coverImageStyleId === 2 && (
              <div className="absolute pointer-events-none">
                <img
                  src={frameImage}
                  alt="frame"
                  className="h-[300px] w-[300px] lg:h-[400px] lg:w-[400px] xl:h-[600px] xl:w-[600px]"
                />
              </div>
            )}
          </div>
        </ModalSquare>
      ) : (
        <ModalSquare className="overflow-scroll">
          <div
            className="p-12 w-full h-full bg-cover bg-[#0eafa9]"
            style={{
              backgroundImage: `url(${preview})`,
            }}
          >
            <label className="relative flex flex-col justify-center h-full w-full rounded-lg border-2 border-dashed border-white p-12 text-center hover:border-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <PlusCircleIcon className="mx-auto h-12 w-12 text-white " />
              <span className="mt-2 block text-sm font-medium text-white ">
                Add image
              </span>
              <input className="hidden" type="file" onChange={onChange} />
            </label>
          </div>
        </ModalSquare>
      )}
      <ModalSquare className="px-6 overflow-scroll">
        <div className="flex flex-col w-full h-full mt-4">
          <h1 className="font-bold">STEP 3</h1>
          {coverImageStyleId === 0 || coverImageStyleId === 1
            ? getYourImage(coverImageStyleId)
            : coverImageStyleId === 2
            ? getPhotoFrame()
            : getVidavuCover()}
          <div className="flex justify-center py-5">
            {imgFile && coverImageStyleId !== 3 ? (
              <VVButton
                type="button"
                onClick={onClickReset}
                className="bg-slate-300 self-center"
              >
                Reset
              </VVButton>
            ) : (
              <VVButton
                type="button"
                onClick={onClickBack}
                className="bg-slate-300 self-center "
              >
                Go Back
              </VVButton>
            )}
            <div className="p-2"></div>

            <VVButton
              type="button"
              onClick={() => onDone()}
              className="bg-indigo-600 self-center text-white"
            >
              Save
            </VVButton>
          </div>
        </div>
      </ModalSquare>
    </>
  );
}
